import { Autocomplete, Grid, Typography } from '@mui/material';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';


const Cabecalho = (props) => {
  const { control, opcoesConcluido, getValues, setValues } = props;
  return (
    <MDBox p={2}>
      <MDBox mb={3}>
        <Typography variant='h6' >Dados do Plano de Corte: {getValues('id') ? getValues('id') : ''}</Typography>
      </MDBox>

      <Grid container spacing={1}>

        <Grid item xs={12} sm={12}>{/*dados do Plano de Corte*/}
          <Grid container spacing={2}>

            <Grid item xs={12} sm={3}>{/*id*/}
              <Controller
                name={'id'}
                control={props.control}
                defaultValue=''
                render={({ field: { ref, ...props } }) =>
                  <FormField
                    
                    key={'id'}
                    id={'id'}
                    autoComplete={false}
                    type='text'
                    label='ID'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={true}
                    inputProps={{
                      disabled: true
                    }}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={3}>{/*Concluido*/}
              <Controller
                name='concluido'
                control={control}
                defaultValue={{ value: 'S' }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesConcluido}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField {...params} label='Concluido' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={getValues('id') ? true : false}
                    {...props}
                  />
                )}

              />
            </Grid>
            <Grid item xs={12} sm={12}>{/*OBSERVACAO*/}
              <Controller
                name={'observacao'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField
                    
                    key={'observacao'}
                    id={'observacao'}
                    autoComplete={false}
                    type='text'
                    label='Observações'
                    rows={3}
                    multiline={true}
                    required={false}
                    disabled={getValues('id') ? true : false} 
                    inputProps={{
                      disabled: getValues('id') ? true : false
                    }}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default Cabecalho;