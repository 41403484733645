import React, { useContext, useEffect, useRef, useState } from 'react';
import RelatorioPadrao from '../../../relatorios/padrao';
import FiltroListaMateriaisProjetosImpressao from './filtro';
import DataPrinterContext from 'hooks/DataPrinter';
import MessagesContext from 'hooks/MessagesContext';
import { copiaProfundaObjetos } from 'commons/utils';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';


export default function RelatorioListaDeMateriaisPedidoProjetos(props) {

    const { vendaAtual, open, setOpen } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso } = messageContext;
    const [openRelatorioPadrao, setOpenRelatorioPadrao] = useState(false);
    const dataPrinterContext = useContext(DataPrinterContext);

    const geraRelatorio = async (params, data) => {


        dataPrinterContext.setEstiloColunas({ backgroundColor: '#F2F4F4', fontSize: '14px' })
        dataPrinterContext.setEstiloLinhas({ backgroundColor: 'white', fontSize: '12px' })

        dataPrinterContext.setTitulo(`Lista de Materiais do Pedido ${vendaAtual} por Projeto`);

        const subtitulo = [];

        let nomestipos = ``;
        params?.tipos?.map((item) => {
            nomestipos = `${nomestipos}${item.nome} / `;
        });

        if (nomestipos !== '') {
            nomestipos = `Tipos ${nomestipos}`;
        }

        if (nomestipos !== '') {
            subtitulo.push('Filtros: ' + nomestipos);
        }

        dataPrinterContext.setSubtitulo(subtitulo);


        const colunas = [];

        //colunas.push({ id: 'projeto', label: 'Projeto', sx: { width: '200px' } });

        if (params.mostraImagem) {
            colunas.push({ id: 'imagem', label: '', sx: { width: '50px' } })
        }

        if (params.mostraTipo) {
            colunas.push({ id: 'tipo', label: 'Tipo', sx: { width: '40px' } });
        }

        if (params.mostraReferencia) {
            colunas.push({ id: 'referencia', label: 'Referência', sx: { width: '50px' } });
        }

        if (params?.mostraNomeProduto) {
            colunas.push({ id: 'produto', label: 'Material' });
        }
        if (params?.mostraCor) {
            colunas.push({ id: 'cor', label: 'Cor', sx: { width: '50px' } });
        }
        if (params?.corte) {
            colunas.push({ id: 'corte', label: 'Corte', sx: { width: '50px' } });
        }
        if (params?.agrupaProduto) {
            colunas.push({ id: 'quantvezes', label: 'Peças', sx: { width: '30px' } });
        }

        //if (params?.mostraMedida) {
            colunas.push({ id: 'quantidade', label: 'Qtde/Medida', sx: { width: '40px' } });
        //}
        if (params.mostraPreco) {
            colunas.push({ id: 'preco', label: 'Preço', sx: { width: '100px' } });
        }
        dataPrinterContext.setColunas(colunas);

        if (params?.agrupaProduto) {

            let dataTemp = [];

            data.map((item) => {

                let achou = false;
                for (let i = 0; i < dataTemp.length; i++) {

                    if ((dataTemp[i].venda_projeto_id === item.venda_projeto_id) &&
                        (parseInt(dataTemp[i].produto_grade_id, 10) === parseInt(item.produto_grade_id, 10)) &&
                        (parseFloat(dataTemp[i].quantidade) === parseFloat(item.quantidade))) {

                        if ((!(params.mostraPreco)) || (parseFloat(dataTemp[i].valor_unitario) === parseFloat(item.valor_unitario))) {
                            dataTemp[i].quantvezes = dataTemp[i].quantvezes + 1;
                            achou = true;
                        }
                    }
                }

                if (!achou) {
                    //add o item no vetortemp
                    dataTemp.push({
                        ...item,
                        quantvezes: 1
                    })
                }
            })

            data = copiaProfundaObjetos(dataTemp);
        }

        const linhasTemp = [];

        /*
       const idprojeto
       pega o id do primeiro Projetos e imprime uma primeira linha
       só com o nome do projeto e os outros dados em branco, lembra de por o negrito

       */
        let id_projeto = data[0].venda_projeto_id
        linhasTemp.push({
            //projeto: '',
            tipo: '-',
            referencia: data[0]?.venda_projeto_id? 'Projeto:' : '',
            produto: data[0]?.venda_projeto_id? data[0]?.venda_projeto_id + '-' + data[0]?.Venda_Projeto?.Projeto?.nome + '-' + data[0]?.Venda_Projeto?.ambiente_instalacao : '<Itens Avulsos>',//Projeto.nome,
            cor: '-',
            quantvezes: '-',
            quantidade: '-',
            preco: '-',
            sx: {
                fontWeight: 'bold',
                fontSize: '14px'
            }
        })


        data?.map((item) => {

            /*if o projeto atual for difetrente do idprojeto salvo entao imprime uma nova linha com o nome do projeto
              e atualiza a variavel pro novo id*/

            if (item.venda_projeto_id != id_projeto) {

                linhasTemp.push({
                    //projeto: '',
                    tipo: '-',
                    referencia: item.venda_projeto_id? 'Projeto:' : '' ,
                    produto: item.venda_projeto_id?  item.venda_projeto_id + '-' + item?.Venda_Projeto?.Projeto?.nome + '-' + item?.Venda_Projeto?.ambiente_instalacao : '<Itens Avulsos>',//Projeto.nome,
                    cor: '-',
                    quantvezes: '-',
                    quantidade: '-',
                    preco: '-',
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px'
                    }
                })

                id_projeto = item.venda_projeto_id

            }

            const tipo_produto_id = item?.Produto_Grade?.Produto?.tipo_produto_id;

            if (params.tipos.filter((item) => parseInt(item.id, 10) === parseInt(tipo_produto_id, 10)).length > 0) {
                let imagem = '';

                if (item?.imagem) {
                    imagem = <img src={`data:image/png[jpg];base64,${item.imagem}`} alt="Imagem do Produto" style={{ maxHeight: 50, maxWidth: 50 }} />;
                }

                linhasTemp.push({
                    projeto: item?.Venda_Projeto?.Projeto.nome + ' - ' + item?.Venda_Projeto?.ambiente_instalacao,//Projeto.nome,
                    imagem,
                    tipo: item?.Produto_Grade?.Produto?.Tipos_Produto?.nome,
                    referencia: item?.Produto_Grade?.Produto?.referencia,
                    produto: item?.Produto_Grade?.Produto?.nome,
                    corte: item.corte,
                    cor: item?.Produto_Grade?.Core?.nome,
                    quantvezes: parseInt(item?.quantvezes, 10) + 'x ',
                    quantidade: item.otimizar == 'S' ? `${parseFloat(item?.quantidade * 1000 )} mm` || '' : parseFloat(item?.quantidade).toFixed(2) || '',
                    preco: parseFloat(item?.valor_unitario).toFixed(2) || '',
                })
            }

        })

        dataPrinterContext.setLinhas(linhasTemp);
    }

    const handleImprimir = async (params = {
        tipos: [],
        projetos: [],
        mostraImagem: false,
        mostraPreco: false
    }) => {

        let complementoUrl = '';

        params?.projetos?.map((item) => {

            complementoUrl = `${complementoUrl}&venda_projeto_id=${item}`;

        });

        if (!(params?.tipos?.length > 0)) {
            MensagemErro('Selecione pelo menos um tipo de material para imprimir o relatório');
            return;
        }

        try {
            let url = `${rotafechada()}vendas/dadosrelatorios/listamateriaisprojetos?venda_id=${vendaAtual}${complementoUrl}&${params.mostraImagem ? 'retornaimagem=S' : 'retornaimagem=N'}`
            // console.log("URL Materiasis Projetos:", url)
            const retorno = await api.get(url);
            const { data } = retorno.data;

            if (!(data.length > 0)) {
                MensagemAviso('Nenhuma informação foi selecionada');
                return;
            }

            await geraRelatorio(params, data);
            setOpenRelatorioPadrao(true);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    return (
        <>
            <RelatorioPadrao open={openRelatorioPadrao} setOpen={setOpenRelatorioPadrao} />
            <FiltroListaMateriaisProjetosImpressao
                open={open}
                setOpen={setOpen}
                handleOk={handleImprimir}
                vendaAtual={vendaAtual}
            />
        </>
    )
}