import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, Grid, IconButton, Typography, Autocomplete, Tooltip } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';

import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaOpcoes from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import InfoIcon from '@mui/icons-material/Info';

const PlanoCorte_Cortes = (props) => {


  const { handleSubmit, control, setValue, getValues, planoCorteAtual, recarregaComponenteOpcoes } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [codigoAtual, setCodigoAtual] = useState('');

  const objPlanoCorte_Cortes = {
    id: { type: 'text' },
    plano_corte_id: { type: 'text' },
    nome: { type: 'text' },
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${rotafechada()}registro_plano_corte_barras/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (!data.nome) {
      camposObrigatorios = `${camposObrigatorios}/Nome`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const gravarRegistro = async (data) => {

    const dados = {};
    //no data esta todos os dados do RHF registrados, o que me interesse é 
    //apenas o objeto "pessoas"
    //assim resgato no data apenas os campos que se referem a pessoas
    //e monto o objeto que sera enviado para o back
    Object.keys(objPlanoCorte_Cortes).forEach((nome) => {
      dados[nome] = '';
      if (nome in data['cortes']) {
        if (typeof data['cortes'][nome] === 'object') {
          dados[nome] = data['cortes'][nome]?.value ? data['cortes'][nome]?.value : '';
        }
        else
          if (typeof data['cortes'][nome] === 'boolean') {
            dados[nome] = data['cortes'][nome] === true ? 'S' : 'N'
          }
          else dados[nome] = data['cortes'][nome];
      }
    })

    dados.empresa_id = sessaolocal.empresa_id || null;
    dados.plano_corte_id = planoCorteAtual
    delete dados.id;

    try {
      validaCampos(dados);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }
    try {
      let retorno;
      let codigoTemp = data.contatos.id;

      if ((data.contatos.id) && (parseInt(data.contatos.id, 10) > 0)) {
        retorno = await api.put(`${rotafechada()}registro_plano_corte_barras/${data.contatos.id}`, dados);
      }
      else {
        retorno = await api.post(`${rotafechada()}registro_plano_corte_barras`, dados);
        codigoTemp = retorno.data.data.id;
      }

      MensagemAviso(`Gravado com sucesso!`);
      setCodigoAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const novoRegistro = () => {
    //limpo os campos
    Object.keys(objPlanoCorte_Cortes).forEach((nome) => {
      if (objPlanoCorte_Cortes[nome].type === 'autocomplete') {
        setValue(`cortes.${nome}`, opcaoDefault[0])
      }
      else
        if (objPlanoCorte_Cortes[nome].type === 'checkbox') {
          setValue(`cortes.${nome}`, false)
        }
        else
          if (objPlanoCorte_Cortes[nome].type === 'text') {
            setValue(`cortes.${nome}`, '')
          }
    })
  }

  const abrirRegistro = async (codigo) => {
    try {
      let url = `${rotafechada()}registro_plano_corte_barras?id=${codigo}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Componente ID ${codigo} não localizado`);
        return;
      }
      setValue('cortes.id', data[0].id);
      setValue(`cortes.nome`, data[0].Produto_Grade.Produto.nome,);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const excluirRegistro = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${rotafechada()}registro_plano_corte_barras/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      novoRegistro();
      setRecarregaTabela(!recarregaTabela);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {
    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }
    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  useEffect(() => {
    if (parseInt(codigoAtual, 10) > 0) {
      abrirRegistro(codigoAtual);
    }
    else novoRegistro();
  }, [planoCorteAtual, codigoAtual, recarregaTabela, recarregaComponenteOpcoes])

  return (
    <MDBox p={2} bgColor='grey-200' mt={2} borderRadius="lg" coloredShadow='secondary'>
      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={excluirRegistro}
      />
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        <Typography variant='h6' mb={2}>Cortes  </Typography>
      </IconButton>
      <Tooltip title="Aqui temos os cortes dessa otimização" placement="top">
        <InfoIcon color="info" sx={{ m: 0.5 }}></InfoIcon>
      </Tooltip>

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <form onSubmit={handleSubmit(gravarRegistro)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>{/*id*/}
              {/* <Controller
                name={`cortes.id`}
                control={props.control}
                defaultValue='Id'
                render={({ field: { ref, ...props } }) =>
                  <FormField
                    //autoComplete={false}
                    type='text'
                    label='Id'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={true}
                    inputProps={{
                      disabled: true
                    }}
                    inputRef={ref}
                    {...props}
                  />}
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>{/*nome*/}
              {/* <Controller
                name={`cortes.nome`}
                control={props.control}
                defaultValue='Nome'
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    //autoComplete={false}
                    type='text'
                    label='Nome do contato'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    {...props}

                  />}
              /> */}
            </Grid>
          </Grid>

          {/* <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
            <MDButton variant="gradient" color="info"
             type="submit"
            // onClick={() => handleSubmit(gravarRegistro)()}
            >Gravar</MDButton>
            <MDButton variant="gradient" color="info" onClick={novoRegistro} sx={{ ml: 2 }}>Novo</MDButton>
          </MDBox> */}
          <MDBox width="100%" display="flex" mt={2}>
            <TabelaOpcoes
              planoCorteAtual={planoCorteAtual}
              abrirRegistro={abrirRegistro}
              excluirRegistro={handleClickExcluir}
              recarregaTabela={recarregaTabela}
              recarregaComponenteOpcoes={recarregaComponenteOpcoes}
              getValues={getValues}
            />
          </MDBox>
        </form>
      </Collapse>
    </MDBox>
  )
}

export default PlanoCorte_Cortes;