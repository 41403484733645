import React, { useState, useEffect, useContext } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import { Icon, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//My Componentes
import FormField from "componentes/FormField";
//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from 'hooks/AuthContext';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import MDTypography from 'components/MDTypography';
import SearchIcon from '@mui/icons-material/Search';
//import PesquisaCest from './pesquisacest';
import PesquisaProdutoCor from './pesquisaprodutograde';
import { formatDate } from 'commons/utils';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from "date-fns/locale";



function Cadastro(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const { setCodigoAbrir, codigoAbrir, setAba } = props;

    const [id, setId] = useState('');
    const [observacao, setObservacao] = useState('');
    const [tamanho, setTamanho] = useState('');
    const [produto_grade_id, setProduto_grade_id] = useState({ value: -1, label: '' });
    //origem
    const [plano_corte_barra_id, setPlano_corte_barra_id] = useState(''); // Plano de Corte
    const [barra_plano_corte_barra_id, setBarra_plano_corte_barra_id] = useState(''); // Barra
    const [retalho_plano_corte_barra_id, setRetalho_plano_corte_barra_id] = useState(''); // Retalho

    const [produtoCor_value, setProdutoCor_value] = useState('');
    const [labelProdutoCor, setLabelProdutoCor] = useState('');
    const [confPesquisaDinamicaProdutoCor, setConfPesquisaDinamicaProdutoCor] = useState({});

    const [dataDescarte, setDataDescarte] = useState(null);

    const handleClickPesquisaProdutoCor = async () => {
        if (!codigoAbrir)
            setConfPesquisaDinamicaProdutoCor({ ...confPesquisaDinamicaProdutoCor, open: true });
    }

    const handleBlurProdutoCor = async () => {
        try {
            if (typeof produtoCor_value === 'string' && (!produtoCor_value || produtoCor_value.trim() === '')) {
                setLabelProdutoCor('');
                setProdutoCor_value('');
                return;
            }
            if (produtoCor_value) {
                let url = `${rotafechada()}produto_grades?id=${produtoCor_value}&empresa_id=${sessaolocal.empresa_id}`;
                const retorno = await api.get(encodeURI(url));
                const { data } = retorno.data;
                // console.log('data produtoCor:', data)
                if ((data) && (data?.length > 0)) {
                    setLabelProdutoCor(data[0].Produto.nome + ' - ' + data[0].Core.nome);
                    setProdutoCor_value(data[0].id);
                } else {
                    setLabelProdutoCor('');
                    setProdutoCor_value('');
                }
            }
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`, 300000);
        }
    }


    const handleFuncaoSelecaoPesquisaDinamicaProdutoCor = (codigo, datasetPesquisa) => {
        /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
        ele retorna os campos que foram configurados*/
        // console.log('retorno da escolha do campo', datasetPesquisa);
        setConfPesquisaDinamicaProdutoCor({ ...confPesquisaDinamicaProdutoCor, open: false });
        setLabelProdutoCor(datasetPesquisa.produto + ' - ' + datasetPesquisa.cor);
        setProdutoCor_value(datasetPesquisa.id);
    }

    const buscaDados = async () => {

        if (!parseInt(props.codigoAbrir, 10) > 0)
            return;

        try {
            const retorno = await api.get(`${rotafechada()}descarte_plano_corte_barras?id=${props.codigoAbrir}`);
            const { data, count } = retorno.data;
            console.log('dados do descarte carregado:', data)

            if (!count > 0) {
                MensagemErro('Nenhuma informação foi selecionada');
                return;
            }

            if (data[0].empresa_id !== sessaolocal.empresa_id) {
                MensagemErro('Registro de outra empresa!');
                return;
            }

            setId(data[0].id);
            setDataDescarte(new Date(data[0].data));


            setPlano_corte_barra_id(data[0].plano_corte_barra_id)
            setBarra_plano_corte_barra_id(data[0].barra_plano_corte_barra_id)
            setRetalho_plano_corte_barra_id(data[0].retalho_plano_corte_barra_id)

            setLabelProdutoCor(data[0].Produto_Grade.Produto.nome + ' - ' + data[0].Produto_Grade.Core.nome)
            setProdutoCor_value(data[0].produto_grade_id)

            setProduto_grade_id({ value: data[0].Produto_Grade.id, label: data[0].Produto_Grade.Produto.nome })

            setTamanho(data[0].tamanho);
            setObservacao(data[0].observacao);

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }

    }

    const handleClickGravar = async (e) => {
        e.preventDefault();

        try {
            MostraTelaEspera('Gravando o centro de custo...');
            let retorno;


            const dados = {
                empresa_id: sessaolocal.empresa_id || null,
                observacao,
                tamanho,
                dataDescarte: dataDescarte,
            }
            //console.log(dados);

            if (codigoAbrir && parseInt(codigoAbrir, 10) > 0)
                retorno = await api.put(`${rotafechada()}descarte_plano_corte_barras/${codigoAbrir}`, dados);
            else {
                retorno = await api.post(`${rotafechada()}descarte_plano_corte_barras`, dados);
                props.setCodigoAbrir(retorno.data.data.id || 0)
            }

            MensagemAviso('Gravado com sucesso!');
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const limpaImputs = async () => {

        setCodigoAbrir('');
        setObservacao('');
        setTamanho('');
        setId('');
        setPlano_corte_barra_id('')
        setBarra_plano_corte_barra_id('')
        setRetalho_plano_corte_barra_id('')
        setDataDescarte('')

    }

    useEffect(() => {
        buscaDados();

    }, [codigoAbrir])


    return (
        <MDBox p={2}>
            <PesquisaProdutoCor
                open={confPesquisaDinamicaProdutoCor.open || false}
                handleClose={
                    () => setConfPesquisaDinamicaProdutoCor({ ...confPesquisaDinamicaProdutoCor, open: false })
                }
                handleClick={handleFuncaoSelecaoPesquisaDinamicaProdutoCor}
            />
            <MDBox width="100%" display="flex" justifyContent="space-between">
                <MDButton
                    variant="gradient"
                    color="secondary"
                    type="button"
                    onClick={() => setAba(0)}
                >Voltar</MDButton>
                <MDButton
                    variant="gradient"
                    circular
                    color="info"
                    type="button"
                    onClick={limpaImputs}
                >
                    <Icon>add</Icon>
                </MDButton>
            </MDBox>

            <form onSubmit={handleClickGravar}>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={2.4}> {/* ID */}
                        <FormField
                            type="text"
                            label="ID"
                            value={id || ''}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} sm={2.4}> {/* DATADESCARTE */}
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                            <DesktopDatePicker
                                label='Data'
                                inputFormat="dd/MM/yyyy"
                                value={dataDescarte}
                                onChange={(e) => setDataDescarte(e)}
                                renderInput={(params) => <FormField {...params} disabled={codigoAbrir ? true : false} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={2.4}> {/* PLANO DE CORE */}
                        <FormField
                            type="text"
                            label="Plano de Corte"
                            value={plano_corte_barra_id || ''}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.4}> {/* BARRA */}
                        <FormField
                            type="text"
                            label="Barra"
                            value={barra_plano_corte_barra_id || ''}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.4}> {/* RETALHO */}
                        <FormField
                            type="text"
                            label="Retalho"
                            value={retalho_plano_corte_barra_id || ''}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>{/*PRODUTOCOR*/}
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={3}>{/*ProdutoCor*/}
                                <FormField
                                    disabled={codigoAbrir ? true : false}
                                    type="text"
                                    label="Produto Cor"
                                    value={produtoCor_value}
                                    onChange={(e) => setProdutoCor_value(e.target.value)}
                                    inputProps={{
                                        onKeyUp: (e) => {
                                            e.preventDefault();
                                            if (e.key === 'F9') {
                                                handleClickPesquisaProdutoCor();
                                            }
                                        },
                                        onBlur: (e) => {
                                            e.preventDefault();
                                            // alert('saiu do campo')
                                            handleBlurProdutoCor();
                                        },
                                    }}
                                    InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                />
                            </Grid>
                            <Grid item xs={12} sm={1}>{/*botao do produtoCor*/}
                                <MDBox width="100%" height='100%' display="flex" justifyContent='center' alignItems='center' >
                                    <SearchIcon
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            handleClickPesquisaProdutoCor();
                                        }}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={8}>{/*label do produtoCor*/}
                                <MDBox width="100%" height='100%' display="flex" alignSelf='flex-start' alignItems='center' >
                                    <Typography variant='caption'>{labelProdutoCor}</Typography>
                                    {/* <Typography variant='caption'>{getNomeProdutoCor}</Typography> */}
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}> {/* TAMANHO */}
                        <FormField
                            type="text"
                            label="Tamanho (mm)"
                            value={tamanho}
                            id='tamanho'
                            onChange={(e) => setTamanho(e.target.value)}
                            disabled={codigoAbrir ? true : false}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}> {/* OBSERVACAO */}
                        <FormField
                            type="text"
                            label="Observação"
                            value={observacao}
                            id='observacao'
                            onChange={(e) => setObservacao(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <MDBox p={2}>{/*Botão gravar */}
                    <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end">
                        <MDButton
                            variant="gradient"
                            color="info"
                            //onClick={handleClickGravar}
                            type="submit"
                        >
                            Gravar
                        </MDButton>
                    </MDBox>
                </MDBox>

            </form>

        </MDBox >
    )

}

export default Cadastro;