import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';
import PlanoCorte_Cortes from './cortes';
import { Icon } from "@mui/material";
import { useNavigate } from 'react-router-dom';


function Cadastro(props) {

  const navigate = useNavigate();

  const { planoCorteAtual, setPlanoCorteAtual, setAba } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, setFocus } = useForm({});
  const [foco, setFoco] = useState(false)

  const [opcoesConcluido, setOpcoesConcluido] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);
  const [opcoesTipoTelefone, setOpcoesTipoTelefone] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'C', label: 'Celular' },
    { value: 'F', label: 'Fixo' },
    { value: 'W', label: 'WhatsApp' },
  ]);
  const [opcoesFisica_juridica, setOpcoesFisica_juridica] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'F', label: 'Fisica' },
    { value: 'J', label: 'Juridica' },
  ]);
  const [opcoesProdutor_rural, setOpcoesProdutor_rural] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);
  const [recarregaComponenteOpcoes, setRecarregaComponenteOpcoes] = useState(false);

  const onSubmit = (data) => {
    handleClickGravar(data);
  }

  const limpaInputs = () => {
    setValue('id', '');
    setValue(`concluido`, { value: 'S', label: 'Sim' });
    setValue(`observacao`, '');
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    // if (!data.fantasia) {
    //   camposObrigatorios = `${camposObrigatorios}/Fantasia`
    // }

    if (!data.concluido) {
      camposObrigatorios = `${camposObrigatorios}/Concluido`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const handleClickGravar = async (data) => {

    //tratando os campos autocomplete
    data.fisica_juridica = data.fisica_juridica?.value ? data.fisica_juridica.value : '';
    data.produtor_rural = data.produtor_rural?.value ? data.produtor_rural.value : '';
    data.concluido = data.concluido?.value ? data.concluido.value : '';

    try {
      validaCampos(data);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      const dados = { empresa_id: sessaolocal.empresa_id || null };

      //pegando do OBJ do RHF apenas os campos que sao do projeto
      dados.concluido = data.concluido;
      dados.observacao = data.observacao;

      let retorno;
      let codigoPlanoCorte = '';

      if (parseInt(planoCorteAtual, 10) > 0) {
        retorno = await api.put(`${rotafechada()}plano_corte_barras/${planoCorteAtual}`, dados);
        codigoPlanoCorte = planoCorteAtual;
        setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);
        setFoco(false)
        MensagemAviso(`Alterado com sucesso! Plano de Corte: ${codigoPlanoCorte}`);
        
      }
      else {
        retorno = await api.post(`${rotafechada()}plano_corte_barras`, dados);
        codigoPlanoCorte = retorno.data.data.id;
        setFoco(true)
        MensagemAviso(`Gravado com sucesso! Plano de Corte: ${codigoPlanoCorte}.
        Escolha um tipo ! `, 10);
      }


      //se for um novo produto entao "recarrego" a pagina
      if (codigoPlanoCorte !== planoCorteAtual) {
        setPlanoCorteAtual(codigoPlanoCorte);
      }
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const novoPlanoCorte = async () => {
    limpaInputs();
    setPlanoCorteAtual('');
  }

  const abrirPlanoCorte = async () => {
    // console.log('abrir planoCorte:', planoCorteAtual);
    try {
      let url = `${rotafechada()}plano_corte_barras?id=${planoCorteAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Plano de Corte ${planoCorteAtual} não localizado`);
        return;
      }
      //console.log("DATA",data)
      limpaInputs();

      setValue('id', data[0].id);
      setValue(`concluido`, { value: data[0].concluido === 'S' ? 'S' : 'N', label: data[0].concluido === 'S' ? 'Sim' : 'Não' });
      setValue('observacao', data[0].observacao);
      setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickVoltar = () => {
    setPlanoCorteAtual('');
    setAba(0);
  }

  useEffect(async () => {
    //await buscaDatasets();
    if (parseInt(planoCorteAtual, 10) > 0) {
      await abrirPlanoCorte();
    }
    else {
      await novoPlanoCorte();
    }
  }, [planoCorteAtual]);


  return (
    <MDBox p={2}>
      <MDBox width="100%" display="flex" justifyContent="space-between">
        <MDButton
          variant="gradient"
          color="secondary"
          type="button"
          onClick={handleClickVoltar}
        >Voltar</MDButton>
        <MDButton
          variant="gradient"
          circular
          color="info"
          type="button"
          onClick={novoPlanoCorte}
        >
          <Icon>add</Icon>
        </MDButton>
      </MDBox>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho
          control={control}
          opcoesConcluido={opcoesConcluido}
          getValues={getValues}
        />
        <MDBox width="100%" display="flex" justifyContent="flex-end">
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
          >
            Gravar
          </MDButton>
        </MDBox>
      </form>
      {
        parseInt(planoCorteAtual, 10) > 0 ?
          <PlanoCorte_Cortes
            control={control}
            getValues={getValues}
            setValue={setValue}
            planoCorteAtual={planoCorteAtual}
            opcoesTipoTelefone={opcoesTipoTelefone}
            handleSubmit={handleSubmit}
            recarregaComponenteOpcoes={recarregaComponenteOpcoes}
          />
          : false
      }
    </MDBox>
  )

}

export default Cadastro;
