import { useContext, useEffect, useState } from "react";

// react-router-dom components
import { Link, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import PreContratoLayout from "./precontratolayout";
import bgImage from "assets/images/personalizados/headerlogin.png";

import { rotaaberta, rotafechada } from '../../commons/urlconf';
import api from '../../commons/api';
import {incMinutes,minutesBetween, retiraEspacos} from '../../commons/utils';

import AuthContext from "../../hooks/AuthContext";
import MessageContext from "../../hooks/MessagesContext";
import { Alert, Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useMaterialUIController, carregaTemplate } from "context";
import { adicionaMascaraCPFCNPJ } from "commons/utils";
import { Image, Window } from "@mui/icons-material";
import FormField from "componentes/FormField";
import { Box } from "@mui/system";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";



const camposEmpresa = {
  id: '',
  tipo_empresa_id: '',
  fisica_juridica: 'J',
  razaosocial: '',
  fantasia: '',
  cnpj: '',
  endereco_rua: '',
  endereco_numero: '',
  endereco_bairro: '',
  endereco_cep: '',
  endereco_cidade: '',
  endereco_cidade_id: '',
  endereco_estado: '',

  nome_socio: '',
  cpf_socio: '',
  rg_socio: '',
  datadenascimento_socio: '',
  telefonecelular_socio: '',

  textotermosdeuso: 'Li e concordo com os Termos de Uso ',
  textopoliticaprivacidade: 'Li e concordo com a Política de Privacidade',
  textorepresentantelegal: 'Confirmo que sou o representante legal pela empresa e tenho poderes legais para contratar serviços em nome da empresa',
  textoinformacoesverificas: 'Confirmo que todos os dados acima são verdadeiro e assumo a responsabilidade jurídica por qualquer informação inverídica',
  textoenviodocumentos: 'Estou ciente que se solicitado terei que enviar documentos que comprovem os dados aqui informados'

}

function DadosSocio(props) {

  const { datasetEmpresas, setDatasetEmpresas, label, disabledImput } = props;

  const alteraCampo = (e) => {
    const dados = { ...datasetEmpresas };
    dados[e.target.name] = e.target.value;
    setDatasetEmpresas(dados);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography variant='h5'>{label}</Typography>
      </Grid>

      <Grid item xs={12} sm={12}>{/*Nome contato*/}
        <FormField
          required={true}
          label="Nome Completo"
          name='nome_socio'
          value={datasetEmpresas.nome_socio}
          onChange={alteraCampo}
          disabled={disabledImput}
        />
      </Grid>
      <Grid item xs={12} sm={6}>{/*CPF Sócio*/}
        <FormField
          required={true}
          label="CPF"
          name='cpf_socio'
          value={datasetEmpresas.cpf_socio}
          onChange={alteraCampo}
          disabled={disabledImput}
        />
      </Grid>
      <Grid item xs={12} sm={6}>{/*RG Sócio*/}
        <FormField
          label="RG"
          name='rg_socio'
          value={datasetEmpresas.rg_socio}
          onChange={alteraCampo}
          disabled={disabledImput}
        />
      </Grid>

      <Grid item xs={12} sm={12}>{/*Data de Nascimento*/}
        <FormField
          required={true}
          label="Data de Nascimento"
          disabled={disabledImput}
          name='datadenascimento_socio'
          value={datasetEmpresas.datadenascimento_socio}
          onChange={alteraCampo}
        />
      </Grid>

      <Grid item xs={12} sm={8}>{/*Telefone*/}
        <FormField
          required={true}
          label="Telefone Celular (Com WhatsApp)"
          disabled={disabledImput}
          name='telefonecelular_socio'
          value={datasetEmpresas.telefonecelular_socio}
          onChange={alteraCampo}
        />
      </Grid>
    </Grid>
  )
}

function PreContratoEtapa2(props) {

  const { token } = props

  const messageContext = useContext(MessageContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

  const fisica_juridica = ["Jurídica", "Física"];

  const [datasetEmpresas, setDatasetEmpresas] = useState({ ...camposEmpresa });

  const opcaoDefaultCidadePessoa = { value: -1, label: '<Selecione uma cidade>', uf: '' };
  const [ufAtual, setUfAtual] = useState({ value: -1, label: '<Selecione uma opção>' });
  const [cidadeAtual, setCidadeAtual] = useState(opcaoDefaultCidadePessoa);

  const [opcoesTiposEmpresas, setOpcoesTiposEmpresas] = useState([{ value: -1, label: '<Selecione uma opção>' }]);
  const [opcoesCidade, setOpcoesCidade] = useState([opcaoDefaultCidadePessoa]);
  const [opcoesEstado, setOpcoesEstado] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceara' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ]);

  const [checkTermosUso, setCheckTermosUso] = useState(false);
  const [checkPoliticaPrivacidade, setCheckPoliticaPrivacidade] = useState(false);
  const [checkRepresentanteLegal, setCheckRepresentanteLegal] = useState(false);
  const [checkEnvioDocumentacao, setCheckEnvioDocumentacao] = useState(false);
  const [checkInformacoesVerificas, setCheckInformacoesVerificas] = useState(false);
  const [checkModuloAdicionalTabelaPreco, setCheckModuloAdicionalTabelaPreco] = useState(true);
  const [checkModuloAdicionalPedidoTempera, setCheckModuloAdicionalPedidoTempera] = useState(true);
  const [checkModuloAdicionalFinanceiro, setCheckModuloAdicionalFinanceiro] = useState(false);
  const [checkModuloAdicionalOtimizacaoAluminio, setCheckModuloAdicionalOtimizacaoAluminio] = useState(false);

  const [tipoContrato, setTipoContrato] = useState('Promocional_Mensal_189');

  const [openMensagemConcluido, setOpenMensagemConcluido] = useState(false);

  const [validaToken, setValidaToken] = useState(true);
  const [validaTokenEmail, setValidaTokenEmail] = useState(true);

  const [disabledImput, setDisabledImput] = useState(false);


  const [tempoSessao, setTempoSessao] = useState('');
  const [minutosVencimento, setMinutosVencimento] = useState(0);

  const baseUrl = `${rotaaberta()}precontrato/`;

  const limpaCampos = () => {
    setDatasetEmpresas({ ...camposEmpresa });
    setCheckTermosUso(false);
    setCheckPoliticaPrivacidade(false);
    setCheckRepresentanteLegal(false);
    setCheckEnvioDocumentacao(false);
    setCheckInformacoesVerificas(false);
  }

  const BuscaCidades = async (paramUf = '') => {

    if (!paramUf) {
      setCidadeAtual(opcaoDefaultCidadePessoa);
    }

    if (`${paramUf}` === '-1') {
      return;
    }

    try {
      let url = `${baseUrl}cidades?uf=${paramUf}`;
      //URL montada para procurar a cidade de acordo com o estado escolhido
      const retorno = await api.post(url, { token, uf: paramUf });
      const { data } = retorno.data;
      let opcoesCidadeTemp = [];
      opcoesCidadeTemp.push({ ...opcaoDefaultCidadePessoa });

      data.map((item) => {
        opcoesCidadeTemp.push({ /*id: item.id,*/ value: item.id, label: item.nome, uf: item.uf })
      })

      setOpcoesCidade(opcoesCidadeTemp);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      setValidaToken(true)
    }

  }

  const buscaTiposEmpresas = async () => {

    try {
      let url = `${baseUrl}tipos_empresas`;
      const retorno = await api.post(url, { token });
      const { data } = retorno.data;

      let opcoesTiposEmpresasTemp = [];
      opcoesTiposEmpresasTemp.push({ value: -1, label: '<Selecione uma opção>' });

      data.map((item) => {
        opcoesTiposEmpresasTemp.push({ value: item.id, label: item.nome });

        if (`${item.nome}`.toUpperCase() === 'VIDRAÇARIA') {
          setDatasetEmpresas({ ...datasetEmpresas, tipo_empresa_id: { value: item.id, label: item.nome } });
        }

      })

      setOpcoesTiposEmpresas(opcoesTiposEmpresasTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      //MensagemErro(`Erro: ${msg}`);
      
    }
  }

  const validaDadosInsercao = (dados = {}) => {


    if ((datasetEmpresas.fisica_juridica !== 'J') && (datasetEmpresas.fisica_juridica !== 'F')) {
      MensagemErro('Escolha o tipo de Contrato (Pessoa Física ou Jurídica)');
      return false;
    }

    const textorepresentantelegal = datasetEmpresas.fisica_juridica === 'J' ? '(representante legal)' : '';

    if (dados.nome_socio === '') {
      MensagemErro(`O nome ${textorepresentantelegal} é um campo obrigatório`);
      return false;
    }

    if (dados.cpf_socio === '') {
      MensagemErro(`O cpf ${textorepresentantelegal} é um campo obrigatório`);
      return false;
    }



    if (dados.datadenascimento_socio === '') {
      MensagemErro(`A data de nascimento ${textorepresentantelegal} é um campo obrigatório`);
      return false;
    }

    if (dados.telefonecelular_socio === '') {
      MensagemErro(`O telefone ${textorepresentantelegal} é um campo obrigatório`);
      return false;
    }

    if (dados.razaosocial === '') {
      MensagemErro('A Razão Social da Empresa é um campo obrigatório');
      return false;
    }

    if (dados.fantasia === '') {
      MensagemErro('O nome Fantasia é um campo obrigatório');
      return false;
    }

    if (dados.cnpj === '') {
      MensagemErro('O cnpj é um campo obrigatório');
      return false;
    }

    if (dados.endereco_rua === '') {
      MensagemErro('A Rua é campo obrigatório');
      return false;
    }

    if (dados.endereco_numero === '') {
      MensagemErro('O número é campo obrigatório');
      return false;
    }

    if (dados.endereco_bairro === '') {
      MensagemErro('O bairro é campo obrigatório');
      return false;
    }

    if (dados.endereco_cidade_id === '') {
      MensagemErro('Escolha um estado e uma  cidade na lista');
      return false;
    }

    if (dados.endereco_estado === '') {
      MensagemErro('Escolha um estado na lista');
      return false;
    }

    return true;
  }

  const gravarEmpresa = async () => {

    if (!((checkTermosUso) && (checkPoliticaPrivacidade) && (checkEnvioDocumentacao) && (checkInformacoesVerificas))) {
      MensagemErro('É necessário concordar com todos os termos para contratar o serviço!');
      return;
    }

    if ((datasetEmpresas.fisica_juridica === 'J') && (!checkRepresentanteLegal)) {
      MensagemErro('É necessário concordar com todos os termos para contratar o serviço!');
      return;
    }


    const dados = {
      tipo_empresa_id: datasetEmpresas.tipo_empresa_id?.value || '',
      fisica_juridica: datasetEmpresas.fisica_juridica || '',
      razaosocial: datasetEmpresas.razaosocial || '',
      fantasia: datasetEmpresas.fantasia || '',
      cnpj: datasetEmpresas.cnpj || '',

      endereco_rua: datasetEmpresas.endereco_rua || '',
      endereco_numero: datasetEmpresas.endereco_numero || '',
      endereco_bairro: datasetEmpresas.endereco_bairro || '',
      endereco_cep: datasetEmpresas.endereco_cep || '',
      endereco_cidade_id: `${parseInt(cidadeAtual?.value)}` || '',
      endereco_cidade: cidadeAtual?.label || '',
      endereco_estado: cidadeAtual?.uf || '',

      nome_socio: datasetEmpresas.nome_socio || '',
      cpf_socio: datasetEmpresas.cpf_socio || '',
      rg_socio: datasetEmpresas.rg_socio || '',
      datadenascimento_socio: datasetEmpresas.datadenascimento_socio || '',
      telefonecelular_socio: datasetEmpresas.telefonecelular_socio || '',

      tipocontrato: tipoContrato || '',
      modulos_adicionais: `${checkModuloAdicionalTabelaPreco ? '|Tabela de Preço(bonificado) ' : ''}${checkModuloAdicionalPedidoTempera ? '|Pedido de Têmpera(bonificado) ' : ''}${checkModuloAdicionalFinanceiro ? '|Módulo Financeiro(R$50,00/mês) ' : ''}${checkModuloAdicionalOtimizacaoAluminio ? '|Módulo Otimização Alumínio(R$25,00/mês) ' : ''}`,

      textotermosdeuso: camposEmpresa.textotermosdeuso || '',
      textopoliticaprivacidade: camposEmpresa.textopoliticaprivacidade || '',
      textorepresentantelegal: camposEmpresa.textorepresentantelegal || '',
      textoinformacoesverificas: camposEmpresa.textoinformacoesverificas || '',
      textoenviodocumentos: camposEmpresa.textoenviodocumentos || '',
      token
    };

    if (dados.fisica_juridica === 'F') {
      dados.razaosocial = dados.nome_socio;
      dados.fantasia = dados.nome_socio;
      dados.cnpj = dados.cpf_socio;
    }

    try {

      if (!validaDadosInsercao(dados)) {
        return;
      }
      MostraTelaEspera('Aguarde... estamos processando seu pedido!');
      const retorno = await api.post(`${baseUrl}`, dados);
      FechaTelaEspera();
      MensagemAviso('Inserido com Sucesso!');
      setOpenMensagemConcluido(true);

      limpaCampos();

    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(() => {
    validaTokenBack();
    buscaTiposEmpresas();
    limpaCampos();


  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      //console.log("teste "+minutosVencimento);

      if (minutosVencimento > 0) {
        setMinutosVencimento((c)=>c-1);
      }
      if (minutosVencimento <= 0){
        setDisabledImput(true)
        setMinutosVencimento((c)=>c=0)
      }

      
    }, (1000*60));

    return () => clearInterval(interval);

  }, [minutosVencimento]);


  useEffect(async () => {
    if (ufAtual) {
      await BuscaCidades(ufAtual.value ? ufAtual.value : '');
    }
    else {
      setCidadeAtual(opcaoDefaultCidadePessoa)
    }
  }, [ufAtual])


  const alteraCampo = (e) => {
    const dados = { ...datasetEmpresas };
    dados[e.target.name] = e.target.value;
    setDatasetEmpresas(dados);
  }

  const alteraFisicaJuridica = (newValue) => {
    const dados = { ...datasetEmpresas };
    dados.fisica_juridica = newValue === 'Jurídica' ? 'J' : 'F';
    setDatasetEmpresas(dados);
  }

  const handleClickEnviarEmail = async () => {

    try {

      MostraTelaEspera('Enviando email...');
      const retorno = await api.post(`${baseUrl}tokenemail`, { token });
      FechaTelaEspera();
      setValidaToken(false)
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const validaTokenBack = async () => {
    try {

      const retorno = await api.post(`${baseUrl}validatoken`, { token });
      setMinutosVencimento(retorno.data.minutos);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      setValidaTokenEmail(false)
    }
  }


  if (validaTokenEmail === false) {
    return (
      <PreContratoLayout image={bgImage}>

        <Dialog
          open={validaToken}
        //onClose={() => setValidaToken(false)}
        >
          <DialogTitle id="alert-dialog-title">
            {' Ativação expirada '}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`O tempo de 1 hora de ativação enviada no seu email venceu, clique no botão abaixo para solicitar um novo email.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setOpenMensagemConcluido(false)} > */}
            <Button onClick={handleClickEnviarEmail} >
              Solicitar
            </Button>
          </DialogActions>

        </Dialog>

        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Contratação dos Serviços
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              
            </MDTypography>
          </MDBox>

          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" pb={3} px={3}>


              <MDBox mt={4} mb={1}>
                <MDTypography display="block" variant="medium" color="black" my={1}>
                  Email de ativação enviado com Sucesso.
                </MDTypography>
                <MDTypography display="block" variant="button" color="black" my={1}>
                  Acesse novamente seu email e clique no novo link
                </MDTypography>
              </MDBox>

            </MDBox>
          </MDBox>
        </Card>
      </PreContratoLayout >
    );
  }
  if (validaTokenEmail === true) {
    return (
      <PreContratoLayout image={bgImage}>

        <Dialog
          open={openMensagemConcluido}
          onClose={() => setOpenMensagemConcluido(false)}
        >
          <DialogTitle id="alert-dialog-title">
            {'Parabéns'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {'Concluído! Aguarde o contato da Invictos Tecnologia para efetuar o pagamento e liberação dos serviços!'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenMensagemConcluido(false)} >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Dialog
          open={validaToken}
          //onClose={() => setValidaToken(false)}
        >
          <DialogTitle id="alert-dialog-title">
            {' Email expirado 222'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`O tempo de 1 hora de ativação do seu email expirou, solicite um novo email de ativação`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setOpenMensagemConcluido(false)} >
            <Button onClick={handleClickEnviarEmail} >
              Solicitar e-mail de ativação
            </Button>
          </DialogActions>
  
        </Dialog> */}

        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Contratação dos Serviços
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Preencha todos os dados e clique em enviar dados
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Seu acesso expira em: {minutosVencimento} minutos
            </MDTypography>
          </MDBox>

          <MDBox pt={4} pb={3} px={2}>
            <MDBox component="form" pb={3} px={2}>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={8}>{/* Tipo de Empresa */}
                      <AutoCompleteStyled
                        inputProps={{
                          name: 'tipo_empresa_id'
                        }}

                        defaultValue=""
                        disabled={disabledImput}
                        value={datasetEmpresas.tipo_empresa_id}
                        options={opcoesTiposEmpresas}
                        renderInput={(params) => (
                          <FormField {...params} label="Tipos" InputLabelProps={{ shrink: true }} />
                        )}
                        onChange={(event, newValue) => {
                          setDatasetEmpresas({ ...datasetEmpresas, tipo_empresa_id: newValue });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>{/* Pessoa */}
                      <AutoCompleteStyled
                        inputProps={{
                          name: 'fisica_juridica'
                        }}

                        defaultValue="Jurídica"
                        value={datasetEmpresas.fisica_juridica === 'J' ? 'Jurídica' : 'Física'}
                        options={fisica_juridica}
                        renderInput={(params) => (
                          <FormField {...params} label="Pessoa" InputLabelProps={{ shrink: true }} />
                        )}
                        onChange={(event, newValue) => {
                          alteraFisicaJuridica(newValue)
                        }}
                        disabled={disabledImput}
                      />
                    </Grid>

                    {datasetEmpresas?.fisica_juridica === 'J' &&
                      <>
                        <Grid item xs={12} sm={12}>
                          <Typography variant='h5'>Dados da Empresa</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>{/* Razão Social */}
                          <FormField
                            required={true}
                            label="Razão Social"
                            placeholder=""
                            name='razaosocial'
                            value={datasetEmpresas.razaosocial}
                            disabled={disabledImput}
                            onChange={alteraCampo} />
                        </Grid>

                        <Grid item xs={12} sm={12}>{/* Nome Fantasia */}

                          <FormField
                            required={true}
                            label="Nome Fantasia"
                            placeholder="" name='fantasia' value={datasetEmpresas.fantasia} onChange={alteraCampo}
                            disabled={disabledImput}
                          />

                        </Grid>
                        <Grid item xs={12} sm={12}>{/* Cnpj */}
                          <FormField
                            required={true}
                            label="Cnpj"
                            disabled={disabledImput}
                            placeholder="" name='cnpj' value={datasetEmpresas.cnpj} onChange={alteraCampo} />
                            
                        </Grid>
                      </>
                    }

                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  <DadosSocio
                    label={`${datasetEmpresas?.fisica_juridica === 'J' ? 'Dados do Representante Legal' : 'Dados do Contratante'}`}
                    datasetEmpresas={datasetEmpresas}
                    setDatasetEmpresas={setDatasetEmpresas}
                    disabledImput={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>{/*Endereço*/}
                  <Typography variant='h5'>Endereço {`${datasetEmpresas?.fisica_juridica === 'J' ? 'da Empresa' : ''}`}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>{/* Rua */}
                  <FormField
                    required={true}
                    label="Rua"
                    placeholder=""
                    name='endereco_rua' value={datasetEmpresas.endereco_rua} onChange={alteraCampo}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>{/* Nº */}
                  <FormField
                    required={true}
                    label="Nº"
                    placeholder=""
                    inputProps={{ type: "number" }}
                    name='endereco_numero' value={datasetEmpresas.endereco_numero} onChange={alteraCampo}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>{/* Bairro */}
                  <FormField
                    required={true}
                    label="Bairro"
                    placeholder=""
                    name='endereco_bairro' value={datasetEmpresas.endereco_bairro} onChange={alteraCampo}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>{/* Estado */}
                  <AutoCompleteStyled

                    inputProps={{
                      name: 'uf'
                    }}

                    value={ufAtual}
                    options={opcoesEstado}
                    required={true}
                    renderInput={(params) => (
                      <FormField {...params} label="Estado" InputLabelProps={{ shrink: true }} />
                    )}
                    onChange={(event, newValue) => {
                      setUfAtual(newValue)
                    }}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={5}>{/* Cidade */}
                  <AutoCompleteStyled

                    inputProps={{
                      name: 'cidade'
                    }}

                    value={cidadeAtual}
                    options={opcoesCidade}
                    renderInput={(params) => (
                      <FormField {...params} label="Cidade" InputLabelProps={{ shrink: true }} />
                    )}
                    onChange={(event, newValue) => {
                      setCidadeAtual(newValue)
                    }}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>{/* CEP */}
                  <FormField
                    label="CEP"
                    placeholder=""
                    name='endereco_cep' value={datasetEmpresas.endereco_cep} onChange={alteraCampo}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={12} m={0}>{/* Plano */}

                  <Box mt={2}>
                    <Typography mt={2} mb={2} variant='h5'>Selecione o plano</Typography>
                    
                    <Box>
                    
                    <Typography mt={1.5} variant='caption' sx={{ fontWeight: 'bold' }}>
                      Valor da Implantação e Treinamento
                    </Typography>
                    
                    <RadioGroup
                      name="valorimplantacao"
                      value="Implantacao_499"
                      // onChange={(e) => setTipoContrato(e.target.value)}
                      row
                    >
                      <FormControlLabel value="Implantacao_499" control={<Radio />} label="Pagamento Único R$ 499,00 (bônus da 1º mensalidade) " />
                    </RadioGroup>
                    </Box>
                    <Divider/>

                    <Typography mt={1.5} variant='caption' sx={{ fontWeight: 'bold' }}>
                      Valor da recorrência
                    </Typography>

                    <RadioGroup
                      name="tipocontrato"
                      value={tipoContrato}
                      onChange={(e) => setTipoContrato(e.target.value)}
                      row
                    >
                      
                      <FormControlLabel value="Promocional_Mensal_189" control={<Radio />} label="Mensal a R$ 189,00/mês " />
                      <FormControlLabel value="Promocional_Trimestral_510" control={<Radio />} label="Trimestral a R$ 510,00/trimestre  ( equivalente a R$170/mês)" />
                      <FormControlLabel value="Promocional_Semestral_960" control={<Radio />} label="Semestral a R$ 960,00/semestre (equivalente a R$160,00/mês)" />
                      <FormControlLabel value="Promocional_Anual_1890" control={<Radio />} label="Anual a R$ 1890/ano (equivalente a R$ 157,50/mês )" />
                    </RadioGroup>
                  </Box>

                  <Box mt={2}>
                    <Typography mt={2} mb={2} variant='h5'>Selecione os módulos adicionais</Typography>
                    <Box>
                      <Checkbox checked={checkModuloAdicionalTabelaPreco} onChange={(e) => setCheckModuloAdicionalTabelaPreco(e.target.checked)} />
                      <Typography mt={1.5} variant='caption' sx={{ fontWeight: 'bold' }}>
                        Múltiplas Tabelas de Preço (bônus)
                      </Typography>
                    </Box>

                    <Box>
                      <Checkbox checked={checkModuloAdicionalPedidoTempera} onChange={(e) => setCheckModuloAdicionalPedidoTempera(e.target.checked)} />
                      <Typography mt={1.5} variant='caption' sx={{ fontWeight: 'bold' }}>
                        Pedido de Têmpera (bônus)
                      </Typography>
                    </Box>

                    <Box>
                      <Checkbox checked={checkModuloAdicionalFinanceiro} onChange={(e) => setCheckModuloAdicionalFinanceiro(e.target.checked)} />
                      <Typography mt={1.5} variant='caption' sx={{ fontWeight: 'bold' }}>
                        Módulo Financeiro (R$50,00/mês)
                      </Typography>
                    </Box>

                    <Box>
                      <Checkbox checked={checkModuloAdicionalOtimizacaoAluminio} onChange={(e) => setCheckModuloAdicionalOtimizacaoAluminio(e.target.checked)} />
                      <Typography mt={1.5} variant='caption' sx={{ fontWeight: 'bold' }}>
                        Módulo Otimização Alumínio (R$25,00/mês)
                      </Typography>
                    </Box>

                  </Box>

                  <Grid item xs={12} sm={12} mt={2}>
                    <Typography variant='h5'>Aceite dos Termos</Typography>
                  </Grid>

                  <Box display='flex' mt={2}>
                    <Checkbox checked={checkTermosUso} onChange={(e) => setCheckTermosUso(e.target.checked)} />
                    <Typography mt={1.5} variant='caption'>
                      <a href="/termosdeuso" target="_blank" rel="noopener noreferrer">
                        {camposEmpresa.textotermosdeuso}
                        (clique aqui para visualizar)
                      </a>
                    </Typography>
                  </Box>
                  <Box display='flex'>
                    <Checkbox checked={checkPoliticaPrivacidade} onChange={(e) => setCheckPoliticaPrivacidade(e.target.checked)} />
                    <Typography mt={1.5} variant='caption'>
                      <a href="/politicadeprivacidade" target="_blank" rel="noopener noreferrer">
                        {camposEmpresa.textopoliticaprivacidade}
                        (clique aqui para visualizar)
                      </a>
                    </Typography>
                  </Box>



                  {
                    datasetEmpresas?.fisica_juridica === 'J' &&
                    <Box display='flex'>
                      <Checkbox checked={checkRepresentanteLegal} onChange={(e) => setCheckRepresentanteLegal(e.target.checked)} />

                      <Typography mt={1.5} variant='caption'> {camposEmpresa.textorepresentantelegal}</Typography>
                    </Box>
                  }

                  <Box display='flex'>
                    <Checkbox checked={checkInformacoesVerificas} onChange={(e) => setCheckInformacoesVerificas(e.target.checked)} />
                    <Typography mt={1.5} variant='caption'>{camposEmpresa.textoinformacoesverificas}</Typography>
                  </Box>

                  <Box display='flex'>
                    <Checkbox checked={checkEnvioDocumentacao} onChange={(e) => setCheckEnvioDocumentacao(e.target.checked)} />
                    <Typography mt={1.5} variant='caption'>{camposEmpresa.textoenviodocumentos}</Typography>
                  </Box>

                </Grid>
              </Grid>

              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth onClick={gravarEmpresa}>
                  ENVIAR
                </MDButton>
              </MDBox>

            </MDBox>
          </MDBox>
        </Card>
      </PreContratoLayout >
    );
  }

}

function PreContratoEtapaInicial() {

  const messageContext = useContext(MessageContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

  const baseUrl = `${rotaaberta()}precontrato/`;
  const [openMensagemConcluido, setOpenMensagemConcluido] = useState(false);
  const [email, setEmail] = useState('');
  const [emailConfirmacao, setEmailConfirmacao] = useState('');
  const [token, setToken] = useState('');

  const handleClickEnviar = async () => {

    if (!((email !== '') && (emailConfirmacao !== ''))) {
      MensagemErro('É necessário preencher os dois campos e eles precisam ser iguais!');
      return;
    }

    if (email !== emailConfirmacao) {
      MensagemErro('Os emails precisam ser iguais!');
      return;
    }

    //retira o espaço e passa para minusculo
    const email_temp=retiraEspacos(`${email}`.toLowerCase());

    try {

      MostraTelaEspera('Aguarde... estamos processando seu pedido!');
      const retorno = await api.post(`${baseUrl}email`, {
        email:email_temp,
        token
      });
      FechaTelaEspera();
      setOpenMensagemConcluido(true);
      setEmail('');
      setEmailConfirmacao('');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }


  const buscaToken15Minutos = async () => {
    try {
      let url = `${baseUrl}token`;
      const retorno = await api.post(url);
      const { msg } = retorno.data;
      setToken(msg);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(() => {
    buscaToken15Minutos();
  }, [])


  return (

    <PreContratoLayout image={bgImage}>

      <Dialog
        open={openMensagemConcluido}
        onClose={() => setOpenMensagemConcluido(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {'Parabéns'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Acesse seu email e clique no link para preencher os dados e fazer a contratação dos serviços (email válido por 1 hora)'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMensagemConcluido(false)} >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Contratação dos Serviços
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Digite o email que você usará para acessar o sistema
          </MDTypography>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>

              <Grid item xs={12} sm={12}>{/*Email*/}
                <FormField
                  label="Email"
                  disabled={false}
                  name='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>{/*Confirmacao do Email*/}
                <FormField
                  label="Confirme o Email"
                  disabled={false}
                  name='emailconfirmacao'
                  value={emailConfirmacao}
                  onChange={(e) => setEmailConfirmacao(e.target.value)}
                />
              </Grid>
            </Grid>

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleClickEnviar}>
                ENVIAR
              </MDButton>
            </MDBox>

          </MDBox>
        </MDBox>
      </Card>
    </PreContratoLayout >
  )
}

function PreContrato() {

  const { token } = useParams();

  return (
    <>
      {
        ((token) && (token !== '')) ?
          <PreContratoEtapa2
            token={token}
          />
          :
          <PreContratoEtapaInicial />
      }
    </>
  )

}

export default PreContrato;
