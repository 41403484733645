import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { useContext } from 'react';
import MessagesContext from 'hooks/MessagesContext';
import { useEffect } from 'react';
import { useState } from 'react';
import { Autocomplete, Checkbox, FormControlLabel, Typography, Grid } from '@mui/material';
import { copiaProfundaObjetos } from 'commons/utils';
import { Box } from '@mui/system';
import MDInput from 'components/MDInput';
import ItemFiltro from 'componentes/ItemFiltro';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';


export default function FiltroListaMateriaisProjetosImpressao(props) {

  const { open, setOpen, handleOk, vendaAtual } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;

  const [tiposProdutos, setTiposProdutos] = useState([]);

  const [mostraImagem, setMostraImagem] = useState(false);
  const [mostraTipo, setMostraTipo] = useState(true);
  const [mostraReferencia, setMostraReferencia] = useState(true);
  const [mostraNomeProduto, setMostraNomeProduto] = useState(true);
  const [mostraCor, setMostraCor] = useState(true);
  //const [mostraMedida, setMostraMedida] = useState(true);
  const [corte, setCorte] = useState(false);
  const [mostraPreco, setMostraPreco] = useState(false);
  const [agrupaProduto, setAgrupaProduto] = useState(false);
  const opcaoDefault = [{ value: -1, label: '<Todos os Projetos>' }];
  const [vendaProjeto, setVendaProjeto] = useState(opcaoDefault);
  const [vetorVendaProjeto, setVetorVendaProjeto] = useState([])

  const setChecked = (id, checked) => {

    const temp = copiaProfundaObjetos(tiposProdutos);

    for (let i = 0; i < temp.length; i++) {
      if (parseInt(temp[i].id, 10) === parseInt(id, 10)) {
        temp[i].checked = checked;
      }
    }

    setTiposProdutos(temp);
  }

  const buscaTiposProdutos = async () => {

    let url = '';
    let retorno;

    try {
      url = `${rotafechada()}tipos_produtos?limit=-1`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      const temp = [];

      data.map((item) => {
        temp.push({ id: item.id, nome: item.nome, checked: true })
      })

      setTiposProdutos(temp);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaVendaProjetos = async () => {

    let url = '';
    let retorno;

    try {
      url = `${rotafechada()}venda_projetos?venda_id=${vendaAtual}`;
      // console.log("Url da venda Projeto", url);
      retorno = await api.get(url);
      // console.log("Retorno Venda Projeto", retorno);
      const { data } = retorno.data;
      const temp = [];

      data.map((item) => {
        temp.push({ value: item.id, label: item.id + '-' + item.Projeto.nome + ' - ' + item.ambiente_instalacao, checked: false })
      })

      setVendaProjeto(temp);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }

  const handleClickOkProjeto = (newValue) => {
    let opc = [...vetorVendaProjeto, newValue.value]
    //console.log("Click do projeto newvalue", newValue);
    setVetorVendaProjeto(opc);

    //console.log("vetorVendaProjeto", vetorVendaProjeto);

  }

  const handleClickDeleteProjeto = (value) => {
    let vetorVendaProjetoTemp = [...vetorVendaProjeto]
    let index = vetorVendaProjetoTemp.indexOf(value);
    if (index > -1) {
      vetorVendaProjetoTemp.splice(index, 1);
    }
    setVetorVendaProjeto(vetorVendaProjetoTemp)

  }

  const handleClickOk = () => {

    if (!handleOk)
      return

    //envio somente os tipos selecionados
    const tipos = tiposProdutos.filter((item) => item.checked);
    const projetos = vetorVendaProjeto

    handleOk({
      tipos,
      mostraImagem,
      mostraTipo,
      mostraReferencia,
      mostraNomeProduto,
      mostraCor,
      //mostraMedida,
      corte,
      mostraPreco,
      agrupaProduto,
      projetos
    }
    );
  }

  const handleClickCancelar = () => {
    setVetorVendaProjeto([]);
    setOpen(false)
  }

  useEffect(() => {

    if (open) {
      buscaTiposProdutos();
      buscaVendaProjetos();
    }

  }, [open])


  return (
    <>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{'Filtros do Relatório'}</DialogTitle>

        <DialogContent dividers>
          <Typography variant='h6'>Escolha os tipos:</Typography>
          <Box display='flex' mb={2}>
            {
              tiposProdutos?.map((item) =>
                <FormControlLabel ml={2} control={<Checkbox checked={item.checked} onChange={(e) => setChecked(item.id, e.target.checked)} />} label={item.nome} />
              )
            }
          </Box>

          <Typography variant='h6'>Colunas</Typography>

          <Box display='flex'>
            <FormControlLabel ml={2} control={<Checkbox checked={mostraImagem} onChange={(e) => setMostraImagem(e.target.checked)} />} label='Imagem' />
            <FormControlLabel ml={2} control={<Checkbox checked={mostraTipo} onChange={(e) => setMostraTipo(e.target.checked)} />} label='Tipo' />
            <FormControlLabel ml={2} control={<Checkbox checked={mostraReferencia} onChange={(e) => setMostraReferencia(e.target.checked)} />} label='Referencia' />
            <FormControlLabel ml={2} control={<Checkbox checked={mostraNomeProduto} onChange={(e) => setMostraNomeProduto(e.target.checked)} />} label='Nome Material' />
            <FormControlLabel ml={2} control={<Checkbox checked={mostraCor} onChange={(e) => setMostraCor(e.target.checked)} />} label='Cor' />
            {/* <FormControlLabel ml={2} control={<Checkbox checked={mostraMedida} onChange={(e) => setMostraMedida(e.target.checked)} />} label='Qtde/Medida' /> */}
            <FormControlLabel ml={2} control={<Checkbox checked={corte} onChange={(e) => setCorte(e.target.checked)} />} label='Ângulo de Corte' />
            <FormControlLabel ml={2} control={<Checkbox checked={mostraPreco} onChange={(e) => setMostraPreco(e.target.checked)} />} label='Preço' />
          </Box>

          <Typography variant='h6'>Outras opções:</Typography>
          <Box display='flex'>
            <FormControlLabel ml={2} control={<Checkbox checked={agrupaProduto} onChange={(e) => setAgrupaProduto(e.target.checked)} />} label='Agrupa Produtos com mesma medida' />
          </Box>

          <Typography variant='h6'>Escolha os Projetos:</Typography>
          <Box>
            <Grid container >
              <Grid item xs={12} sm={12} mt={1} mb={1} >{/*Projeto*/}
                <AutoCompleteStyled
                  options={vendaProjeto}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <FormField {...params} />}
                  onChange={(event, newValue) => {
                    handleClickOkProjeto(newValue)
                  }}
                  defaultValue={{ id: -1, value: -1, label: '<Todos os Projetos>' }}

                />
              </Grid>
            </Grid>
            {
              vetorVendaProjeto?.map((item) => {
                return (
                  <ItemFiltro
                    value={item}
                    label={''}
                    handleClickDelete={handleClickDeleteProjeto}
                  />)
              })
            }
          </Box>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClickCancelar}>
            Cancelar
          </Button>
          <Button onClick={handleClickOk}>Ok</Button>
        </DialogActions>
      </Dialog>

    </>)
}