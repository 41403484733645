import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Grid, Icon, InputAdornment, Tooltip, Typography, Checkbox, Divider, Box } from "@mui/material";
import Switch from '@mui/material/Switch';
import FormField from "componentes/FormField";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import AuthContext from 'hooks/AuthContext';
import { formataFloat, formatDate, formataValor, incMonth, incDay, dateIsValid } from "commons/utils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import HelpIcon from '@mui/icons-material/Help';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PercentIcon from '@mui/icons-material/Percent';
import CancelIcon from '@mui/icons-material/Cancel';
import { ptBR } from "date-fns/locale";
import CurrencyInput from "componentes/MascaraMonetaria";

function DadosRateio(props) {

    const { optionCategoria, optionCentroCusto, dadosRateio, setDadosRateio, valor, porcentagem } = props;
    let subTotalRateado = 0;
    let subTotalRateadoP = 0;

    return (
        <>
            <Grid container spacing={2}>

                < Grid item xs={12} sm={12} >
                    <Typography variant='title' fontWeight="bold">Informar categoria e centro de custo</Typography>
                    <Divider
                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                        style={{
                            marginTop: 20,
                            border: "none",
                            height: 2,
                            margin: 0,
                        }}
                    />
                </Grid >
                {dadosRateio.map((item, index) => {
                    return (
                        <>
                            <Grid item xs={12} sm={4}>{/*CATEGORIA*/}
                                <AutoCompleteStyled
                                    options={optionCategoria}
                                    disableClearable //Retira o X (clear)
                                    getOptionLabel={(option) => option.label || ""}
                                    getOptionDisabled={(option) => option.mae === true}
                                    renderInput={(params) => <FormField required {...params} label="Categoria:" />}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option.mae === true ?
                                                <Typography variant="subtitle" style={{ fontWeight: '900', color: 'black' }}>{option.label}</Typography>
                                                :
                                                <Typography variant="subtitle" style={{ font: 'inherit' }}>{`   ${option.label}`}</Typography>
                                            }
                                        </Box>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={item.categoria}
                                    onChange={(event, newValue) => {
                                        let tempCategoria = [...dadosRateio]
                                        tempCategoria[index].categoria = newValue
                                        setDadosRateio(tempCategoria);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={1.5}> {/* VALOR */}
                                <FormField
                                    disabled={dadosRateio.length == 1 ? true : false}
                                    required={true}
                                    type="number"
                                    label="Valor total (R$)"
                                    value={item.valorTotal}
                                    onChange={(e) => {
                                        let tempValor = [...dadosRateio]
                                        tempValor[index].valorTotal = e.target.value
                                        tempValor[index].porcentagem = formataFloat((e.target.value * 100) / valor)
                                        setDadosRateio(tempValor);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment sx={{ color: 'green' }} position="end"><AttachMoneyIcon /></InputAdornment>,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={1.5}> {/* PORCENTAGEM */}
                                <FormField
                                    disabled={dadosRateio.length == 1 ? true : false}
                                    required={true}
                                    type="number"
                                    label="Porcentagem"
                                    value={item.porcentagem}
                                    onChange={(e) => {
                                        let tempPorcentagem = [...dadosRateio]
                                        tempPorcentagem[index].porcentagem = e.target.value
                                        tempPorcentagem[index].valorTotal = formataFloat((e.target.value * valor) / 100)

                                        setDadosRateio(tempPorcentagem)
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment sx={{ color: 'blue' }} position="end"><PercentIcon /></InputAdornment>,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}> {/*CENTRO_CUSTO*/}
                                <AutoCompleteStyled
                                    options={optionCentroCusto}
                                    getOptionLabel={(option) => option.label || ""}
                                    renderInput={(params) => <FormField {...params} label="Centro de custo" />}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={item.centroCusto}
                                    onChange={(event, newValue) => {
                                        let tempCentroCusto = [...dadosRateio]
                                        tempCentroCusto[index].centroCusto = newValue
                                        setDadosRateio(tempCentroCusto);
                                    }}
                                    style={{ cursor: "not-allowed" }}
                                />
                            </Grid>
                            {
                                dadosRateio.length > 2 &&
                                <>
                                    <Grid item xs={12} sm={1}> {/*REMOVER*/}
                                        <Tooltip title="Remover">
                                            <MDButton
                                                size="medium"
                                                type="button"
                                                iconOnly
                                                onClick={() => {
                                                    let tempRemover = [...dadosRateio];
                                                    tempRemover.splice(index, 1)

                                                    setDadosRateio(tempRemover)
                                                }}
                                            >
                                                <Icon>close</Icon>
                                            </MDButton>

                                        </Tooltip>
                                    </Grid>
                                </>
                            }
                        </>
                    )
                })}
            </Grid>
            <Grid container mt={2}> {/*ADICIONAR CATEGORIA*/}
                <MDBox width="100%" display="flex" justifyContent="flex-start">
                    <MDButton
                        variant="outlined"
                        color="info"
                        type="button"
                        onClick={() => {
                            let vetorTemp = [...dadosRateio];

                            vetorTemp.push({
                                categoria: { value: "", label: "" },
                                valorTotal: parseFloat(0).toFixed(2),
                                porcentagem: parseFloat(0).toFixed(2),
                                centroCusto: { value: "", label: "" },
                            },)
                            setDadosRateio(vetorTemp)
                        }}
                        size='small'
                    > + Adicionar categoria
                    </MDButton>
                </MDBox>
            </Grid>
            <Divider
                sx={{ bgcolor: (theme) => theme.palette.divider }}
                style={{
                    marginTop: 20,
                    border: "none",
                    height: 2,
                    margin: 0,
                }}
            />

            <Grid container mt={2}> {/*TOTAL RATEIO*/}
                <MDBox width="100%" display="flex" justifyContent="flex-end">
                    {dadosRateio.map((item, index) => {
                        subTotalRateado = parseFloat(subTotalRateado) + parseFloat(item.valorTotal)
                        subTotalRateadoP = parseFloat(subTotalRateadoP) + parseFloat(item.porcentagem)
                    })}

                    <Grid item xs={12} sm={2} > {/*RESTANTE DO RATEIO %% E R$*/}
                        <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                            <MDTypography variant='subtitle2'>
                                {'Restante do rateio  '}
                                <Tooltip
                                    title="É o restante que não foi distribuído a partir do valor total desta 
                            conta. Para completar o rateio, este restante precisa estar zerado."
                                    placement="top"
                                >
                                    <HelpIcon color="info" />
                                </Tooltip>
                            </MDTypography>
                            <MDTypography fontWeight="bold" variant='body1'>
                                {`${(100 - subTotalRateadoP) < 0 ? 0 : parseFloat(100 - subTotalRateadoP).toFixed(2)} % | ${formataValor(parseFloat(valor).toFixed(2) - parseFloat(subTotalRateado).toFixed(2))}`}
                            </MDTypography>
                        </MDBox>
                    </Grid>

                    <Grid item xs={12} sm={2} > {/*TOTAL RATEADO*/}
                        <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                            <MDTypography variant='subtitle2'>
                                {'Total rateado'}
                            </MDTypography>

                            <MDTypography fontWeight="bold" variant='body1'>
                                {formataValor(subTotalRateado)}
                            </MDTypography>
                        </MDBox>
                    </Grid>


                </MDBox>
            </Grid>
        </>

    )
}

function DadosParcela(props) {
    //1x ou mais parcelas sem lancamentos
    const { dadosParcelas, setDadosParcelas, optionFormaPagamento, optionBanco, parcelamento,
        nome, valor, banco, formaPagamento, vencimento, intervaloParcelas, agendado,
        tipo, valorOnBlur, competenciaCheck } = props;

    const CriaParcelas = async () => {
        if (dadosParcelas.length > 0) {
            let tempVetor = []
            let dataHoje = vencimento;
            let todosMenosUmValor = 0
            let todosMenosUmPercentual = 0

            //Faço esse map para descobrir o valor em aberto que restou após deduzir do valor da conta as parcelas com lancamentos
            let valor_total_parcela = 0
            dadosParcelas.map((item) => {
                valor_total_parcela = valor_total_parcela + parseFloat(item.valor)
            })

            for (let i = 0; i < parcelamento.value; i++) {
                if (i == 0) {
                    tempVetor.push({
                        vencimento: vencimento,
                        data_competencia: vencimento,
                        valor: parseFloat((valor_total_parcela / parcelamento.value).toFixed(2)),
                        percentual: parseFloat((100 / parcelamento.value).toFixed(2)),
                        formaPagamento: formaPagamento,
                        banco: banco,
                        descricao: `${nome}`,
                        agendado: agendado,
                        observacao: ''
                    },)
                    todosMenosUmValor = todosMenosUmValor + parseFloat((valor_total_parcela / parcelamento.value).toFixed(2))
                    todosMenosUmPercentual = todosMenosUmPercentual + parseFloat((100 / parcelamento.value).toFixed(2))
                }
                else
                    if (i == (parcelamento.value - 1)) {
                        dataHoje = (incDay(dataHoje, intervaloParcelas))
                        tempVetor.push({
                            vencimento: dataHoje,
                            data_competencia: dataHoje,
                            valor: parseFloat((valor_total_parcela - todosMenosUmValor).toFixed(2)),
                            percentual: parseFloat((100 - todosMenosUmPercentual).toFixed(2)),
                            formaPagamento: formaPagamento,
                            banco: banco,
                            descricao: `${nome}`,
                            agendado: agendado,
                            observacao: ''

                        },)
                    }
                    else {
                        dataHoje = (incDay(dataHoje, intervaloParcelas))
                        tempVetor.push({
                            vencimento: dataHoje,
                            data_competencia: dataHoje,
                            valor: parseFloat((valor_total_parcela / parcelamento.value).toFixed(2)),
                            percentual: parseFloat((100 / parcelamento.value).toFixed(2)),
                            formaPagamento: formaPagamento,
                            banco: banco,
                            descricao: `${nome}`,
                            agendado: agendado,
                            observacao: ''
                        },)
                        todosMenosUmValor = todosMenosUmValor + parseFloat((valor_total_parcela / parcelamento.value).toFixed(2))

                        todosMenosUmPercentual = todosMenosUmPercentual + parseFloat((100 / parcelamento.value).toFixed(2))
                    }
            }
            //console.log("Dados parcelas antes de setar o state", tempVetor)
            setDadosParcelas(tempVetor)
        }

    }

    const AlteraParcelasFormaPagamento = () => {
        if (formaPagamento && dadosParcelas.length > 0) {
            let tempVetor = [...dadosParcelas]

            for (let i = 0; i < dadosParcelas.length; i++) {
                tempVetor[i].formaPagamento = formaPagamento
            }
            setDadosParcelas(tempVetor)
        }

    }

    const AlteraParcelasBanco = async () => {
        if (banco && dadosParcelas.length > 0) {
            let tempVetor = [...dadosParcelas]

            for (let i = 0; i < parcelamento.value; i++) {
                tempVetor[i].banco = banco
            }
            setDadosParcelas(tempVetor)
        }

    }

    const AlteraParcelasNome = async () => {
        if (nome && dadosParcelas.length > 0) {
            let tempVetor = [...dadosParcelas]

            for (let i = 0; i < parcelamento.value; i++) {
                tempVetor[i].descricao = `${nome} ${i + 1}/${parcelamento.value}`
            }
            setDadosParcelas(tempVetor)
        }

    }

    const AlteraParcelasAgendado = async () => {
        if (agendado) {
            let tempVetor = [...dadosParcelas]

            for (let i = 0; i < parcelamento.value; i++) {
                tempVetor[i].agendado = agendado
            }
            setDadosParcelas(tempVetor)
        }

    }

    const AlteraParcelasIntervalo = async () => {
        let tempVetor = [...dadosParcelas]

        for (let i = 0; i < parcelamento.value; i++) {
            let tempData = tempVetor[i].vencimento
            tempVetor[i].vencimento = (incDay(tempData, intervaloParcelas))
        }
        setDadosParcelas(tempVetor)

    }


    useEffect(async () => {
        await CriaParcelas();

    }, [parcelamento, intervaloParcelas])

    useEffect(() => {
        AlteraParcelasFormaPagamento();

    }, [formaPagamento])

    useEffect(() => {
        AlteraParcelasBanco();

    }, [banco])

    useEffect(() => {
        AlteraParcelasNome();

    }, [nome])

    useEffect(() => {
        AlteraParcelasAgendado();

    }, [agendado])

    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant='title' fontWeight="bold" >Parcelas</Typography>
                    <Divider
                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                        style={{
                            marginTop: 20,
                            border: "none",
                            height: 2,
                            margin: 0,
                        }}
                    />
                </Grid>

                {
                    dadosParcelas?.map((item, index) => {
                        return (
                            <>
                                <Grid item xs={12} sm={0.3} ml={2.5}>{/*INDICE*/}
                                    {<><strong>{index + 1}</strong></>}
                                </Grid>

                                <Grid item xs={12} sm={2}>{/*VENCIMENTO*/}
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                        <DesktopDatePicker
                                            required={true}
                                            label='Vencimento'
                                            inputFormat="dd/MM/yyyy"
                                            value={item.vencimento}
                                            onChange={(e) => {
                                                let tempVencimento = [...dadosParcelas]
                                                tempVencimento[index].vencimento = e
                                                setDadosParcelas(tempVencimento);
                                            }}

                                            renderInput={(params) => <FormField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} sm={1.5}> {/* VALOR PARCELA EM ABERTO R$*/}
                                    <CurrencyInput
                                        //disabled={index + 1 === parcelamento.value ? true : false}
                                        required={true}
                                        label="Valor (R$)"
                                        value={parseFloat(item.valor)}
                                        onChange={(e) => {
                                            let tempValor = [...dadosParcelas]
                                            tempValor[index].valor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                                            setDadosParcelas(tempValor);
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment sx={{ width: '5%', color: 'green' }} position="end"><AttachMoneyIcon /></InputAdornment>,
                                            // onBlur: (e) => {
                                            //     e.preventDefault();
                                            //     handleBlurValor(item.valor, index);
                                            // },
                                            // disabled: index + 1 == parcelamento.value ? true : false,
                                        }}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                    />
                                </Grid>

                                {/* <Grid item xs={12} sm={1}> {/* PERCENTUAL 
                                    <FormField
                                        disabled={index + 1 === parcelamento.value ? true : false}
                                        required={true}
                                        type="text"
                                        label="Percentual"
                                        value={formataFloat(item.percentual)}
                                        onChange={(e) => {
                                            let tempPercentual = [...dadosParcelas]
                                            tempPercentual[index].percentual = e.target.value
                                            setDadosParcelas(tempPercentual);
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment sx={{ width: '5%', color: 'blue' }} position="end"><PercentIcon /></InputAdornment>,
                                            // onBlur: (e) => {
                                            //     e.preventDefault();
                                            //     handleBlurPercentual(item.percentual, index);
                                            // },
                                            disabled: index + 1 === parcelamento.value ? true : false,
                                        }}
                                        style={{ cursor: "not-allowed" }}
                                    />
                                </Grid> */}

                                <Grid item xs={12} sm={2}> {/*FORMA_PAGAMENTO*/}
                                    <AutoCompleteStyled
                                        options={optionFormaPagamento}
                                        getOptionLabel={(option) => option.label || ""}
                                        renderInput={(params) => <FormField {...params} label="Forma de pagamento" />}
                                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                        value={item.formaPagamento}
                                        onChange={(event, newValue) => {
                                            let tempFormaPagamento = [...dadosParcelas]
                                            tempFormaPagamento[index].formaPagamento = newValue
                                            setDadosParcelas(tempFormaPagamento);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*BANCO OU CAIXA*/}
                                    <AutoCompleteStyled
                                        options={optionBanco}
                                        getOptionLabel={(option) => option.label || ""}
                                        renderInput={(params) => <FormField {...params} label="Conta de Pagamento:" />}
                                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                        value={item.banco}
                                        onChange={(event, newValue) => {
                                            let tempBanco = [...dadosParcelas]
                                            tempBanco[index].banco = newValue
                                            setDadosParcelas(tempBanco);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={tipo == 'D' ? 2.5 : 4}> {/*DESCRIÇÃO QUE É O NOME*/}
                                    <FormField
                                        type="text"
                                        label="Descrição"
                                        value={item.descricao}
                                        onChange={(e) => {
                                            let tempDescricao = [...dadosParcelas]
                                            tempDescricao[index].descricao = e.target.value
                                            setDadosParcelas(tempDescricao);
                                        }}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                    />
                                </Grid>

                                {
                                    tipo == 'D' &&
                                    <>
                                        <Grid item xs={12} sm={1.5}> {/*AGENDADO*/}
                                            <MDBox width="100%" display="flex" alignItems='center' flexDirection='row'>
                                                <Checkbox
                                                    checked={item.agendado}
                                                    onChange={(e) => {
                                                        let tempAgendado = [...dadosParcelas]
                                                        tempAgendado[index].agendado = e.target.checked
                                                        setDadosParcelas(tempAgendado);
                                                    }}
                                                />
                                                <MDTypography pr={0.5} variant="caption">
                                                    {'Agendado'}
                                                </MDTypography>
                                                <Tooltip
                                                    title={`Ao marcar como agendado, será considerada a data ${dateIsValid(item.vencimento) ? formatDate(item.vencimento) ? formatDate(item.vencimento, 'DD/MM/YYYY') : 'Data invalida' : 'Data invalida'}
                                            A baixa será feita no sistema somente quando for marcado manualmente como pago`}
                                                    placement="top"
                                                >
                                                    <HelpIcon color="info" />
                                                </Tooltip>
                                            </MDBox>
                                        </Grid>
                                    </>
                                }

                                {
                                    !competenciaCheck &&
                                    <>
                                        <Grid item xs={12} sm={0.5}> {/*espaço*/}

                                        </Grid>
                                        <Grid item xs={12} sm={2}> {/*DATA_COMPETENCIA*/}
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                                <DesktopDatePicker
                                                    required={true}
                                                    label='Data competência'
                                                    inputFormat="dd/MM/yyyy"
                                                    value={item.data_competencia}
                                                    onChange={(e) => {
                                                        let tempDataCompetencia = [...dadosParcelas]
                                                        tempDataCompetencia[index].data_competencia = e
                                                        setDadosParcelas(tempDataCompetencia);
                                                    }}

                                                    renderInput={(params) => <FormField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        {/* <Grid item xs={12} sm={9.5}>

                                        </Grid> */}
                                    </>

                                }

                                {
                                    competenciaCheck &&
                                    <Grid item xs={12} sm={0.5}> {/*ESPAÇO*/}
                                    </Grid>
                                }

                                <Grid item xs={12} sm={!competenciaCheck ? 9.5 : 11.5}> {/*OBSERVAÇÃO DA PARCELA*/}
                                    <FormField
                                        type="text"
                                        label={`Observação da parcela ${index + 1}`}
                                        value={item.observacao}
                                        onChange={(e) => {
                                            let tempObs = [...dadosParcelas]
                                            tempObs[index].observacao = e.target.value
                                            setDadosParcelas(tempObs);
                                        }}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}> {/*LINHA DIVISORIA*/}
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 20,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>
                            </>
                        )
                    })
                }
            </Grid>
        </>

    )
}

function DadosParcelaLancamento(props) {
    //Somente parcelas com lancamentos
    const { dadosParcelasLancamento, setDadosParcelasLancamento, optionFormaPagamento, optionBanco, parcelamento,
        tipo, competenciaCheck } = props;
    //console.log(`DadosParcelaLancamento:${competenciaCheck}`)

    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant='title' fontWeight="bold" >Parcelas</Typography>
                    <Divider
                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                        style={{
                            marginTop: 20,
                            border: "none",
                            height: 2,
                            margin: 0,
                        }}
                    />
                </Grid>

                {
                    dadosParcelasLancamento?.map((item, index) => {
                        return (
                            <>
                                <Grid item xs={12} sm={0.3} ml={2.5}>{/*INDICE*/}
                                    {<><strong>{item.numero_parcela}</strong></>}
                                </Grid>

                                <Grid item xs={12} sm={2}>{/*VENCIMENTO*/}
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                        <DesktopDatePicker
                                            required={true}
                                            label='Vencimento'
                                            inputFormat="dd/MM/yyyy"
                                            value={item.vencimento}
                                            onChange={(e) => {
                                                let tempVencimento = [...dadosParcelasLancamento]
                                                tempVencimento[index].vencimento = e
                                                setDadosParcelasLancamento(tempVencimento);
                                            }}

                                            renderInput={(params) => <FormField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} sm={1.5}> {/* VALOR PARCELA PAGA PARCIALR$*/}
                                    <CurrencyInput
                                        required={true}
                                        label="Valor (R$)"
                                        value={parseFloat(item.valor)}
                                        onChange={(e) => {
                                            let tempValor = [...dadosParcelasLancamento]
                                            tempValor[index].valor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                                            setDadosParcelasLancamento(tempValor);
                                        }}
                                        helperText={item.valor > 0 ? false :
                                            <>
                                                <CancelIcon color={'error'} />
                                                <Typography pl={1} fontWeight="small" variant="caption" color={'error'}>O valor da parcela deve ser superior a 0</Typography>
                                            </>}
                                        InputProps={{
                                            endAdornment: <InputAdornment sx={{ width: '5%', color: `${item.valor > 0 ? 'green' : 'red'}` }} position="end"> {item.valor > 0 ? <AttachMoneyIcon /> : <ErrorOutlineIcon />}</InputAdornment>,

                                        }}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                    />
                                </Grid>

                                <Grid item xs={12} sm={1.5}> {/* SALDO R$*/}
                                    <FormField
                                        disabled={true}
                                        required={true}
                                        type="number"
                                        label="Saldo (R$)"
                                        value={item.valor - item.total_lancamentos}
                                        helperText={item.valor - item.total_lancamentos >= 0 ? false :
                                            <>
                                                <CancelIcon color={'error'} />
                                                <Typography pl={1} fontWeight="small" variant="caption" color={'error'}>O saldo da parcela deve ser igual ou superior a 0</Typography>
                                            </>}
                                        InputProps={{
                                            endAdornment: <InputAdornment sx={{ width: '5%', color: `${item.valor - item.total_lancamentos >= 0 ? 'green' : 'red'}` }} position="end"> {item.valor - item.total_lancamentos >= 0 ? <AttachMoneyIcon /> : <ErrorOutlineIcon />}</InputAdornment>,

                                            disabled: true,
                                        }}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                    />
                                </Grid>

                                <Grid item xs={12} sm={tipo == 'D' ? 3.5 : 3.5}> {/*DESCRIÇÃO QUE É O NOME*/}
                                    <FormField
                                        type="text"
                                        label="Descrição"
                                        value={item.descricao}
                                        onChange={(e) => {
                                            let tempDescricao = [...dadosParcelasLancamento]
                                            tempDescricao[index].descricao = e.target.value
                                            setDadosParcelasLancamento(tempDescricao);
                                        }}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                    />
                                </Grid>

                                {
                                    tipo == 'D' &&
                                    <>
                                        <Grid item xs={12} sm={1.5}> {/*AGENDADO*/}
                                            <MDBox width="100%" display="flex" alignItems='center' flexDirection='row'>
                                                <Checkbox
                                                    checked={item.agendado}
                                                    onChange={(e) => {
                                                        let tempAgendado = [...dadosParcelasLancamento]
                                                        tempAgendado[index].agendado = e.target.checked
                                                        setDadosParcelasLancamento(tempAgendado);
                                                    }}
                                                />
                                                <MDTypography pr={0.5} variant="caption">
                                                    {'Agendado'}
                                                </MDTypography>
                                                <Tooltip
                                                    title={`Ao marcar como agendado, será considerada a data ${dateIsValid(item.vencimento) ? formatDate(item.vencimento) ? formatDate(item.vencimento, 'DD/MM/YYYY') : 'Data invalida' : 'Data invalida'}
                                            A baixa será feita no sistema somente quando for marcado manualmente como pago`}
                                                    placement="top"
                                                >
                                                    <HelpIcon color="info" />
                                                </Tooltip>
                                            </MDBox>
                                        </Grid>
                                    </>
                                }

                                <Grid item xs={12} sm={tipo == 'D' ? 1.5 : 3}> {/* EM BRANCO */}
                                </Grid>

                                {
                                    !competenciaCheck &&
                                    <>
                                        <Grid item xs={12} sm={0.5}> {/*espaço*/}

                                        </Grid>
                                        <Grid item xs={12} sm={2}> {/*DATA_COMPETENCIA*/}
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                                <DesktopDatePicker
                                                    required={true}
                                                    label='Data competência'
                                                    inputFormat="dd/MM/yyyy"
                                                    value={item.data_competencia}
                                                    onChange={(e) => {
                                                        let tempDataCompetencia = [...dadosParcelasLancamento]
                                                        tempDataCompetencia[index].data_competencia = e
                                                        setDadosParcelasLancamento(tempDataCompetencia);
                                                    }}

                                                    renderInput={(params) => <FormField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        {/* <Grid item xs={12} sm={9.5}>

                                        </Grid> */}
                                    </>

                                }

                                {
                                    competenciaCheck &&
                                    <Grid item xs={12} sm={0.5}> {/*ESPAÇO*/}
                                    </Grid>
                                }

                                <Grid item xs={12} sm={!competenciaCheck ? 9.5 : 11.5}> {/*OBSERVAÇÃO DA PARCELA*/}
                                    <FormField
                                        type="text"
                                        label={`Observação da parcela ${index + 1}`}
                                        value={item.observacao}
                                        onChange={(e) => {
                                            let tempObs = [...dadosParcelasLancamento]
                                            tempObs[index].observacao = e.target.value
                                            setDadosParcelasLancamento(tempObs);
                                        }}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}> {/*LINHA DIVISORIA*/}
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 20,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>
                            </>
                        )
                    })
                }
            </Grid>
        </>

    )
}

export default function DialogoEditarConta(props) {

    const { open, message, handleCancel, title, setCodigoAbrir, codigoAbrir, tipo } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [vencimento, setVencimento] = useState(new Date());
    const [diferenca, setDiferenca] = useState(0);
    const [intervaloParcelas, setIntervaloParcelas] = useState(30);
    const [formaPagamento, setFormaPagamento] = useState();
    const [optionFormaPagamento, setOptionFormaPagamento] = useState([]);
    const [banco, setBanco] = useState();
    const [optionBanco, setOptionBanco] = useState([
        { value: -1, label: "<Escolha uma conta de pagamento>" },
    ]);

    const optionParcelamento = [
        { value: 1, label: "1x" },
        { value: 2, label: "2x" },
        { value: 3, label: "3x" },
        { value: 4, label: "4x" },
        { value: 5, label: "5x" },
        { value: 6, label: "6x" },
        { value: 7, label: "7x" },
        { value: 8, label: "8x" },
        { value: 9, label: "9x" },
        { value: 10, label: "10x" },
        { value: 11, label: "11x" },
        { value: 12, label: "12x" },
        { value: 13, label: "13x" },
        { value: 14, label: "14x" },
        { value: 15, label: "15x" },
        { value: 16, label: "16x" },
        { value: 17, label: "17x" },
        { value: 18, label: "18x" },
        { value: 19, label: "19x" },
        { value: 20, label: "20x" },
        { value: 21, label: "21x" },
        { value: 22, label: "22x" },
        { value: 23, label: "23x" },
        { value: 24, label: "24x" },
        { value: 25, label: "25x" },
        { value: 26, label: "26x" },
        { value: 27, label: "27x" },
        { value: 28, label: "28x" },
        { value: 29, label: "29x" },
        { value: 30, label: "30x" },
        { value: 31, label: "31x" },
        { value: 32, label: "32x" },
        { value: 33, label: "33x" },
        { value: 34, label: "34x" },
        { value: 35, label: "35x" },
        { value: 36, label: "36x" },
        { value: 37, label: "37x" },
        { value: 38, label: "38x" },
        { value: 39, label: "39x" },
        { value: 40, label: "40x" },
        { value: 41, label: "41x" },
        { value: 42, label: "42x" },
        { value: 43, label: "43x" },
        { value: 44, label: "44x" },
        { value: 45, label: "45x" },
        { value: 46, label: "46x" },
        { value: 47, label: "47x" },
        { value: 48, label: "48x" },
        { value: 49, label: "49x" },
        { value: 50, label: "50x" },
        { value: 51, label: "51x" },
        { value: 52, label: "52x" },
        { value: 53, label: "53x" },
        { value: 54, label: "54x" },
        { value: 55, label: "55x" },
        { value: 56, label: "56x" },
        { value: 57, label: "57x" },
        { value: 58, label: "58x" },
        { value: 59, label: "59x" },
        { value: 60, label: "60x" },

    ];
    const [parcelamento, setParcelamento] = useState(optionParcelamento[0]);

    const [pago, setPago] = useState(false);
    const [manual, setManual] = useState('');
    const [agendado, setAgendado] = useState(false);
    const [competenciaCheck, setCompetenciaCheck] = useState(true);
    const [valor, setValor] = useState(0);
    var valor_total_parcela = 0, valor_total_parcela_lancamento = 0, valor_total_parcela2 = 0, valor_total_parcela_lancamento2 = 0
    const [valorOnBlur, setValorOnBlur] = useState(true);

    const [dadosRateio, setDadosRateio] = useState([]);

    const [dadosParcelas, setDadosParcelas] = useState([]);
    const [dadosParcelasLancamento, setDadosParcelasLancamento] = useState([]);
    const [idsParcelas, setIdsParcelas] = useState([]);

    const [nome, setNome] = useState('');
    const [codigoReferencia, setCodigoReferencia] = useState('');
    const [observacao, setObservacao] = useState('');

    const [porcentagem, setPorcentagem] = useState(0);
    const [dataCompetencia, setDataCompetencia] = useState(new Date());
    const [categoria_id, setCategoria_id] = useState('');
    const [optionCategoria, setOptionCategoria] = useState([{}]);

    const [centro_custo_id, setCentro_custo_id] = useState('');
    const [optionCentroCusto, setOptionCentroCusto] = useState([{}]);

    const [pessoa, setPessoa] = useState({ value: -1, label: 'Escolha uma pessoa' });
    const [optionPessoa, setOptionPessoa] = useState([{}]);

    const [rateio, setRateio] = useState(false);

    const buscadados = async () => {
        try {
            MostraTelaEspera('Carregando informações da conta')

            const retorno = await api.get(`${rotafechada()}contas/full?id=${props.codigoAbrir}`);
            const { data, countParcelas, count } = retorno.data;

            // console.log('DADOS CONTA:', data)
            //console.log('QUANTIDADE PARCELAS:', countParcelas)

            //Conta
            setPessoa({ value: parseInt(data[0].Pessoa.id), label: `${data[0].Pessoa.razao_social} / ${data[0].Pessoa.fantasia}` })
            setCodigoReferencia(data[0]?.codigo_referencia)
            setDataCompetencia(new Date(data[0].data_competencia))
            setNome(data[0].nome)
            setManual(data[0].manual)
            //setValor(parseFloat(data[0].valor).toFixed(2))
            setValor(parseFloat(data[0].valor))
            setObservacao(data[0]?.observacao)
            setRateio(data[0].rateio == 'S' ? true : false)
            setCategoria_id({ value: parseInt(data[0]?.Categorium?.id), label: data[0]?.Categorium?.nome })
            setCentro_custo_id({ value: parseInt(data[0]?.Centro_Custo?.id), label: data[0]?.Centro_Custo?.nome })
            setVencimento(new Date(data[0].Parcelas_Conta[0].vencimento))

            //setBanco({ value: data[0].Parcelas_Conta[0]?.Banco?.id, label: data[0]?.Parcelas_Conta[0]?.Banco?.nome })
            //setFormaPagamento({ value: data[0]?.Parcelas_Conta[0]?.Forma_Pagamento?.id, label: data[0]?.Parcelas_Conta[0]?.Forma_Pagamento?.nome })

            //Categorias rateadas
            if (data[0].rateio == 'S') {
                let vetorTemp = [];
                data[0].Rateio_Categoria.map((item) => {
                    vetorTemp.push({
                        categoria: { value: item.Categorium.id, label: item.Categorium.nome },
                        valorTotal: (parseFloat(data[0].valor) * parseFloat(item.percentual / 100)),
                        porcentagem: parseFloat(item.percentual).toFixed(2),
                        centroCusto: { value: item?.Centro_Custo?.id, label: item?.Centro_Custo?.nome },
                    },)
                })
                setDadosRateio(vetorTemp)
            }



            let vetorTemp = [];
            let vetorTempid = [];
            let vetorTempLanc = [];
            let contador = 0
            data[0].Parcelas_Conta.map((item) => {
                if (formatDate(new Date(data[0].Parcelas_Conta[0].data_competencia), "DD/MM/YYYY") !== formatDate(new Date(data[0].Parcelas_Conta[0].vencimento), "DD/MM/YYYY")) {
                    contador++
                }

                //Parcelas com lançamentos
                if (item.total_lancamentos) {
                    vetorTempLanc.push({
                        id: item.id,
                        numero_parcela: item.numero_parcela,
                        vencimento: new Date(item.vencimento),
                        data_competencia: new Date(item.data_competencia),
                        valor: formataFloat(parseFloat(item.valor)),
                        total_lancamentos: formataFloat(parseFloat(item.total_lancamentos)),
                        percentual: 0,//formataFloat(100 / parcelamento.value),
                        formaPagamento: { value: item?.Forma_Pagamento?.id, label: item?.Forma_Pagamento?.nome },
                        banco: { value: item?.Banco?.id, label: item?.Banco?.nome },
                        descricao: item.nome,
                        agendado: item.agendado === 'S' ? true : false,
                        observacao: item.observacao
                    },)
                }
                //Parcelas sem lançamentos
                else {
                    vetorTemp.push({
                        vencimento: new Date(item.vencimento),
                        data_competencia: new Date(item.data_competencia),
                        valor: formataFloat(parseFloat(item.valor)),
                        percentual: (parseFloat(item.valor) * 100) / parseFloat(data[0].valor),
                        formaPagamento: { value: item?.Forma_Pagamento?.id, label: item?.Forma_Pagamento?.nome },
                        banco: { value: item?.Banco?.id, label: item?.Banco?.nome },
                        descricao: item.nome,
                        agendado: item.agendado === 'S' ? true : false,
                        observacao: item.observacao
                    },)
                    vetorTempid.push(item.id)

                }
            })
            //setCompetenciaCheck(formatDate(new Date(data[0].Parcelas_Conta[0].data_competencia), "DD/MM/YYYY") == formatDate(new Date(data[0].Parcelas_Conta[0].vencimento), "DD/MM/YYYY")? true : false)
            setCompetenciaCheck(contador > 0 ? false : true)
            setParcelamento({ value: vetorTemp.length, label: `${vetorTemp.length}x` })
            setDadosParcelas(vetorTemp)
            setDadosParcelasLancamento(vetorTempLanc)
            setIdsParcelas(vetorTempid)





            FechaTelaEspera()
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaCategoria = async () => {
        try {
            const complementoUrl = `?tipo=${tipo}`;
            const url = `${rotafechada()}categoria/listarordenado${complementoUrl}`;
            // console.log("***URL categoria:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesCategoriaTemp = [{ value: -1, label: '<Escolha uma categoria>' }];

            data.map((item) => {
                //if (!item.verificador) {
                opcoesCategoriaTemp.push({ value: item.id, label: item.nome, mae: item?.filho ? true : false, });
                //}
            });
            setOptionCategoria(opcoesCategoriaTemp);

            return opcoesCategoriaTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaFormaPagamento = async () => {
        try {
            const url = `${rotafechada()}forma_pagamento?order=nome&limit=-1`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesFormaPagamentoTemp = [{}];

            data.map((item) => {
                opcoesFormaPagamentoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionFormaPagamento(opcoesFormaPagamentoTemp);

            return opcoesFormaPagamentoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaBanco = async () => {
        try {
            const url = `${rotafechada()}banco?order=nome&limit=-1&ativo=S`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesBancoTemp = [{}];

            data.map((item) => {
                opcoesBancoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionBanco(opcoesBancoTemp);

            return opcoesBancoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaPessoa = async () => {
        try {
            const url = `${rotafechada()}pessoas?order=razao_social&limit=-1&tipo_pessoa_id=${tipo == 'D' ? 3 : 1}`;
            //console.log("***URL pessoa:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesPessoaTemp = [];

            data.map((item) => {
                opcoesPessoaTemp.push({ value: item.id, label: item.fisica_juridica == 'F' ? item.id + ' - ' + item.razao_social : item.id + ' - ' + item.razao_social + ' / ' + item.fantasia });
            });
            setOptionPessoa(opcoesPessoaTemp);

            return opcoesPessoaTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaCentroCusto = async () => {
        try {
            const url = `${rotafechada()}centro_custo?order=nome&limit=-1&ativo=S`;
            //console.log("***URL centro de custo:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesCentroCustoTemp = [{}];

            data.map((item) => {
                opcoesCentroCustoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionCentroCusto(opcoesCentroCustoTemp);

            return opcoesCentroCustoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const handleClickGravar = async (e) => {

        let msg = ''

        if (pessoa == '') {
            msg = msg + 'pessoa, '
        }
        if (nome == '') {
            msg = msg + 'descrição, '
        }
        if (valor <= 0) {
            msg = msg + 'valor, '
        }
        if ((!categoria_id) && (!rateio)) {
            msg = msg + 'categoria'
        }
        if (rateio) {
            let countCategoria = 0
            let countValor = 0
            let subTotalRateado = 0
            let subTotalRateadoP = 0
            for (let i = 0; i < dadosRateio.length; i++) {
                if (dadosRateio[i].categoria.value == '') {
                    countCategoria++
                }
                if (dadosRateio[i].valorTotal == 0) {
                    countValor++
                }
                subTotalRateado = parseFloat(subTotalRateado) + parseFloat(dadosRateio[i].valorTotal)
                subTotalRateadoP = parseFloat(subTotalRateadoP) + parseFloat(dadosRateio[i].porcentagem)
            }
            if (countCategoria > 0)
                msg = msg + 'categoria do rateio'
            if (countValor > 0)
                msg = msg + ' | Valor do rateio deve ser superior a 0'
            if ((parseFloat(valor).toFixed(2) - parseFloat(subTotalRateado).toFixed(2)) != 0)
                msg = msg + ' | Somatória do rateio difere do valor da conta'
        }
        if (msg.length > 0) {
            MensagemAlerta(`Campo(s) obrigatório(s): ${msg}`)
        }
        else {
            try {
                MostraTelaEspera('Gravando edição da conta...');
                let dadosRateioBack = []
                let dadosParcelasBack = []
                let dadosParcelasLancamentoBack = []
                if (rateio) { //Rateio
                    for (let i = 0; i < dadosRateio.length; i++) {
                        dadosRateioBack.push(
                            {
                                categoria_id: dadosRateio[i]?.categoria.value,
                                centro_custo_id: dadosRateio[i]?.centroCusto?.value ? dadosRateio[i].centroCusto.value : null,
                                valor: dadosRateio[i].valorTotal,
                                porcentagem: dadosRateio[i].porcentagem,
                                conta_id: props.codigoAbrir
                            }
                        )

                    }
                }
                //preparando parcelas sem lancamentos para o envio para o back
                for (let i = 0; i < dadosParcelas.length; i++) {
                    dadosParcelasBack.push(
                        {
                            empresa_id: sessaolocal.empresa_id || null,
                            conta_id: props.codigoAbrir,
                            forma_pagamento_id: dadosParcelas[i].formaPagamento?.value,
                            banco_id: dadosParcelas[i].banco?.value,
                            nome: dadosParcelas[i].descricao,
                            data_competencia: competenciaCheck == true ? dadosParcelas[i].vencimento : dadosParcelas[i].data_competencia,
                            vencimento: dadosParcelas[i].vencimento,
                            previsao: new Date(),
                            valor: parseFloat(dadosParcelas[i].valor),
                            saldo: parseFloat(dadosParcelas[i].valor),
                            agendado: dadosParcelas[i].agendado ? 'S' : 'N',
                            observacao: dadosParcelas[i]?.observacao,
                            numero_parcela: (dadosParcelasLancamento.length > 0 ? dadosParcelasLancamento.length + (i + 1) : i + 1), /// ???????????????
                            ativo: 'S'
                        }
                    )
                }
                //preparando parcelas com lancamentos para o envio para o back
                for (let i = 0; i < dadosParcelasLancamento.length; i++) {
                    dadosParcelasLancamentoBack.push(
                        {
                            id: dadosParcelasLancamento[i].id,
                            empresa_id: sessaolocal.empresa_id || null,
                            conta_id: props.codigoAbrir,
                            forma_pagamento_id: dadosParcelasLancamento[i].formaPagamento?.value,
                            banco_id: dadosParcelasLancamento[i].banco?.value,
                            nome: dadosParcelasLancamento[i].descricao,
                            data_competencia: competenciaCheck == true ? dadosParcelasLancamento[i].vencimento : dadosParcelasLancamento[i].data_competencia,
                            vencimento: dadosParcelasLancamento[i].vencimento,
                            valor: parseFloat(dadosParcelasLancamento[i].valor),
                            agendado: dadosParcelasLancamento[i].agendado ? 'S' : 'N',
                            observacao: dadosParcelasLancamento[i]?.observacao,
                            numero_parcela: (i + 1), /// ???????????????
                            ativo: 'S'
                        }
                    )
                }
                const dados = {
                    empresa_id: sessaolocal.empresa_id || null,
                    conta_id: props.codigoAbrir,
                    data_competencia: dataCompetencia,
                    pessoa_id: pessoa.value,
                    nome,
                    observacao,
                    valor,
                    rateio: rateio === true ? 'S' : 'N',
                    dadosRateio: dadosRateioBack,
                    categoria_id: rateio == true ? dadosRateioBack[0]?.categoria_id ? dadosRateioBack[0]?.categoria_id : null : categoria_id?.value ? categoria_id?.value : null,
                    centro_custo_id: rateio == true ? dadosRateioBack[0]?.centro_custo_id ? dadosRateioBack[0]?.centro_custo_id : null : centro_custo_id?.value ? centro_custo_id?.value : null,
                    codigo_referencia: codigoReferencia,
                    parcelasLancamento: dadosParcelasLancamentoBack,
                    parcelas: dadosParcelasBack,
                    idsParcelas
                }
                // console.log('Dados Conta Edição', dados)

                await api.put(`${rotafechada()}contas/full/${props.codigoAbrir}`, dados);


                MensagemAviso('Gravado com sucesso!');
                FechaTelaEspera();
                handleCancel()
                limpaImputs()
            }
            catch (error) {
                FechaTelaEspera();
                const msg = error.response?.data?.msg || error;
                MensagemErro(`Erro: ${msg}`);
            }
        }
    }

    const handleClickCopiaCompetenciaVencimento = async (e) => {
        let tempParcelas = [...dadosParcelas]
        tempParcelas.map((item, index) => {
            item.data_competencia = dataCompetencia
        })

        setDadosParcelas(tempParcelas)

    }

    const limpaImputs = () => {

        setCodigoAbrir('');
        setNome('');
        setPessoa('');
        setValor(0);
        setDataCompetencia(new Date());
        setVencimento(new Date());
        setCategoria_id('');
        setCentro_custo_id('');
        setCodigoReferencia('');
        setObservacao('');
        setPago(false);
        setCompetenciaCheck(true);
        setAgendado(false);
        setBanco({});
        setFormaPagamento({});
        setDadosParcelas([])
        setIntervaloParcelas(30);
        setParcelamento(optionParcelamento[0]);
        setRateio(false);

    }


    useEffect(() => {
        if (open == true) {
            buscadados();
            buscaFormaPagamento();
            buscaBanco();
            buscaCategoria();
            buscaPessoa();
            buscaCentroCusto();
        }

    }, [open])

    useEffect(() => {
        let temp = [{}]
        if (!rateio) {
            temp = [{
                categoria: { value: "", label: "" },
                valorTotal: 0,
                porcentagem: 50,
                centroCusto: { value: "", label: "" },

            },
            {
                categoria: { value: "", label: "" },
                valorTotal: 0,
                porcentagem: 50,
                centroCusto: { value: "", label: "" },

            },]
        }
        if (rateio) {
            temp = [{
                categoria: { value: "", label: "" },
                valorTotal: parseFloat(valor / 2).toFixed(2),
                porcentagem: parseFloat(50).toFixed(2),
                centroCusto: { value: "", label: "" },

            },
            {
                categoria: { value: "", label: "" },
                valorTotal: parseFloat(valor / 2).toFixed(2),
                porcentagem: parseFloat(50).toFixed(2),
                centroCusto: { value: "", label: "" },

            },]
        }
        setDadosRateio(temp)

    }, [rateio])

    useEffect(() => {

        if ((parcelamento.value >= 1) && ((formatDate(vencimento, 'DD/MM/YYYY')) == (formatDate(new Date(), 'DD/MM/YYYY')))) {
            setVencimento(incMonth(new Date(), 1))
        }
        if (parcelamento.value == 0) {
            setDadosParcelas([])
            setVencimento(new Date())
        }
    }, [parcelamento])

    useEffect(() => {
        if ((vencimento > new Date()) && parcelamento.value == 0) {
            setParcelamento(optionParcelamento[1])
        }

    }, [vencimento])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{title}{tipo == 'D' ? '- Despesa' : '- Receita'}</MDTypography>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    handleCancel()
                                    limpaImputs()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>


                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>


                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">
                        {/* {message} */}

                        <form onSubmit={handleClickGravar}>
                            <MDBox ml={2} mr={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONTA */}
                                <Grid container spacing={2} mt={1} p={2}>

                                    <Grid item xs={12} sm={12}> {/* TITULO*/}
                                        <MDTypography fontWeight="bold" pb={2}>Informações da conta</MDTypography>
                                        <Divider
                                            sx={{ bgcolor: (theme) => theme.palette.divider }}
                                            style={{
                                                marginTop: 20,
                                                border: "none",
                                                height: 2,
                                                margin: 0,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={2}> {/* DATA_COMPETENCIA */}
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                            <DesktopDatePicker
                                                required={true}
                                                label='Data competencia'
                                                inputFormat="dd/MM/yyyy"
                                                value={dataCompetencia}
                                                onChange={(e) => setDataCompetencia(e)}
                                                renderInput={(params) => <FormField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>{/*PESSOA*/}
                                        <AutoCompleteStyled
                                            disabled={manual == "S" ? false : true}
                                            disableClearable //Retira o X (clear)
                                            defaultValue={pessoa}
                                            required={true}
                                            options={optionPessoa}
                                            getOptionLabel={(option) => option.label || ""}
                                            renderInput={(params) => <FormField required {...params} label={tipo == 'D' ? 'Fornecedor:' : 'Cliente:'} />}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            value={pessoa}
                                            onChange={(event, newValue) => {
                                                setPessoa(newValue);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}> {/* NOME */}

                                        <FormField
                                            disabled={manual == "S" ? false : true}
                                            //maxLength={5}
                                            required={true}
                                            type="text"
                                            label="Descrição"
                                            value={nome}
                                            onChange={(e) => setNome(e.target.value)}
                                            helperText={nome?.length > 0 ? false :
                                                <>
                                                    <CancelIcon color={'error'} />
                                                    <Typography pl={1} fontWeight="small" variant="caption" color={'error'}>Campo obrigatório</Typography>
                                                </>
                                            }
                                            //inputProps={{ maxLength: 12 }}
                                            InputProps={{
                                                disabled: manual == "S" ? false : true
                                            }}

                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={2}> {/* VALOR CONTA*/}
                                        <CurrencyInput
                                            disabled={manual == "S" ? false : true}
                                            autoFocus
                                            placeholder="0,00"
                                            required={true}
                                            label="Valor"
                                            value={valor}
                                            helperText={valor > 0 ? false :
                                                <>
                                                    <CancelIcon color={'error'} />
                                                    <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Valor deve ser superior a 0</Typography>
                                                </>
                                            }
                                            //onChange={(e) => setValor(e.target.value)}
                                            onChange={(e) => {
                                                const tempValor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                                                setValor(tempValor)
                                            }}
                                            InputProps={{
                                                //inputMode: 'numeric',
                                                //pattern: '[0-9]*',
                                                startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                                onBlur: (e) => {
                                                    e.preventDefault();
                                                    setValorOnBlur(!valorOnBlur)
                                                },
                                                disabled: manual == "S" ? false : true
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={1} > {/*RATEIO*/}
                                        <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                                            <MDTypography variant='caption'>
                                                {'Habilitar rateio'}
                                            </MDTypography>
                                            <Switch
                                                checked={rateio}
                                                onChange={(event) => setRateio(event.target.checked)}
                                            />
                                        </MDBox>
                                    </Grid>

                                    <Grid item xs={12} sm={4} >{/*CATEGORIA*/}
                                        <AutoCompleteStyled
                                            disabled={rateio == true ? true : false}
                                            disableClearable //Retira o X (clear)
                                            required={true}
                                            options={optionCategoria}
                                            getOptionDisabled={(option) => option.mae === true}
                                            getOptionLabel={(option) => option.label || ""}
                                            renderInput={(params) => <FormField required {...params} label="Categoria: novo" />}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.mae === true ?
                                                        <Typography variant="subtitle" style={{ fontWeight: '900', color: 'black' }}>{option.label}</Typography>
                                                        :
                                                        <Typography variant="subtitle" style={{ font: 'inherit' }}>{`   ${option.label}`}</Typography>
                                                    }
                                                </Box>
                                            )}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            value={categoria_id}
                                            onChange={(event, newValue) => {
                                                setCategoria_id(newValue);
                                            }}
                                            style={{ cursor: "not-allowed" }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={0.5}>{/*INFO CATEGORIA*/}
                                        <Tooltip
                                            title="As categorias são utilizadas para formar um Plano de Contas."
                                            placement="top"
                                        >
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </Grid>

                                    <Grid item xs={12} sm={3}> {/*CENTRO_CUSTO*/}
                                        <AutoCompleteStyled mr={2}
                                            disabled={rateio == true ? true : false}
                                            defaultValue={centro_custo_id}
                                            options={optionCentroCusto}
                                            getOptionLabel={(option) => option.label || ""}
                                            renderInput={(params) => <FormField {...params} label="Centro de custo" />}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            value={centro_custo_id}
                                            onChange={(event, newValue) => {
                                                setCentro_custo_id(newValue);
                                            }}
                                            style={{ cursor: "not-allowed" }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={0.5}>{/*INFO CENTRO_CUSTO*/}
                                        <Tooltip pl={1} title="O Centro de Custo é uma informação financeira para ajudar no controle de receitas
                                    e despesas de determinada área ou projeto da empresa." placement="top">
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </Grid>

                                    <Grid item xs={12} sm={2.5}> {/* CODIGO_REFERENCIA */}
                                        <FormField
                                            type="text"
                                            label="Código de referência"
                                            value={codigoReferencia}
                                            onChange={(e) => setCodigoReferencia(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={0.5}>{/*INFO CODIGO_REFERENCIA*/}
                                        <Tooltip title="Código informado manualmente para identificação da conta. Exceto quando 
                                    originado por uma venda, neste caso o código é automáticamente preenchido com o número da venda." placement="top">
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </Grid>

                                    {rateio === true &&
                                        <>
                                            <Grid item xs={12} md={12}>{/* RATEIO DADOS */}
                                                <DadosRateio
                                                    label={'Informar categoria e centro de custo'}
                                                    optionCategoria={optionCategoria}
                                                    categoria_id={categoria_id}
                                                    setCategoria_id={setCategoria_id}
                                                    valor={valor}
                                                    setValor={setValor}
                                                    optionCentroCusto={optionCentroCusto}
                                                    centro_custo_id={centro_custo_id}
                                                    setCentro_custo_id={setCentro_custo_id}
                                                    porcentagem={porcentagem}
                                                    setPorcentagem={setPorcentagem}
                                                    dadosRateio={dadosRateio}
                                                    setDadosRateio={setDadosRateio}
                                                />
                                            </Grid>
                                        </>
                                    }

                                </Grid>
                            </MDBox >

                            {
                                dadosParcelasLancamento.length > 0 &&
                                <MDBox ml={2} mr={2} mt={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* PARCELAS COM LANCAMENTO */}
                                    <Grid container spacing={2} mt={2} p={2}>
                                        <Grid item xs={12} sm={12}> {/* TITULO*/}
                                            <MDTypography fontWeight="bold" pb={2}>Parcelas com lançamentos</MDTypography>
                                            <Divider
                                                sx={{ bgcolor: (theme) => theme.palette.divider }}
                                                style={{
                                                    marginTop: 20,
                                                    border: "none",
                                                    height: 2,
                                                    margin: 0,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>{/*PARCELAS COM LANCAMENTO*/}
                                            <DadosParcelaLancamento
                                                optionFormaPagamento={optionFormaPagamento}
                                                optionBanco={optionBanco}
                                                dadosParcelasLancamento={dadosParcelasLancamento}
                                                setDadosParcelasLancamento={setDadosParcelasLancamento}
                                                parcelamento={parcelamento}
                                                nome={nome}
                                                valor={valor}
                                                banco={banco}
                                                formaPagamento={formaPagamento}
                                                vencimento={vencimento}
                                                setVencimento={setVencimento}
                                                intervaloParcelas={intervaloParcelas}
                                                agendado={agendado}
                                                tipo={tipo}
                                                FechaTelaEspera={FechaTelaEspera}
                                                MostraTelaEspera={MostraTelaEspera}
                                                valorOnBlur={valorOnBlur}
                                                competenciaCheck={competenciaCheck}
                                            />
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            }

                            {
                                dadosParcelas.length > 0 ?
                                    <MDBox ml={2} mr={2} mt={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* LANCAMENTO */}
                                        <Grid container spacing={2} mt={2} p={2}>

                                            <Grid item xs={12} sm={12}> {/* TITULO*/}
                                                <MDTypography fontWeight="bold" pb={2}>Condição de {tipo == 'D' ? 'pagamento' : 'recebimento'}</MDTypography>
                                                <Divider
                                                    sx={{ bgcolor: (theme) => theme.palette.divider }}
                                                    style={{
                                                        marginTop: 20,
                                                        border: "none",
                                                        height: 2,
                                                        margin: 0,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={1.5}>{/*PARCELAMENTO*/}
                                                <AutoCompleteStyled
                                                    required={true}
                                                    defaultValue={optionParcelamento[0]}
                                                    disableClearable //Retira o X (clear)
                                                    options={optionParcelamento}
                                                    getOptionLabel={(option) => option.label || ""}
                                                    renderInput={(params) => <FormField required {...params} label="Parcelamento:" />}
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    value={parcelamento}
                                                    onChange={(event, newValue) => {
                                                        setParcelamento(newValue);
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={1.6}> {/* VENCIMENTO */}
                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                                    <DesktopDatePicker
                                                        //disabled={parcelamento.value == 0 ? true : false}
                                                        required={true}
                                                        label={parcelamento.value >= 1 ? '1° Vencimento' : 'Vencimento'}
                                                        inputFormat="dd/MM/yyyy"
                                                        value={vencimento}
                                                        onChange={(e) => {
                                                            //vencimentoGlobal = e
                                                            setVencimento(e)
                                                        }}
                                                        renderInput={(params) => <FormField required {...params} />}

                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            {
                                                parcelamento?.value >= 1 &&
                                                <>
                                                    <Grid item xs={12} sm={1}> {/* INTERVALO PARCELAS */}
                                                        {/* <MDBox width="100%" display="flex" alignItems='space-between' flexDirection='row'> */}
                                                        <MDBox width="100%" display="flex" alignItems='center' justifyContent="space-between" flexDirection='row'>
                                                            <FormField
                                                                defaultValue={30}
                                                                type="number"
                                                                label="Intervalo"
                                                                value={intervaloParcelas}
                                                                onChange={(e) => setIntervaloParcelas(e.target.value)}
                                                            />
                                                            <Tooltip
                                                                title={`Representa o intervalo em dias do vencimento entre as parcelas`}
                                                                placement="top"
                                                            >
                                                                <HelpIcon color="info" />
                                                            </Tooltip>
                                                        </MDBox>
                                                    </Grid>
                                                </>
                                            }

                                            <Grid item xs={12} sm={2}> {/*FORMA_PAGAMENTO*/}
                                                <AutoCompleteStyled
                                                    freeSolo
                                                    forcePopupIcon={true}
                                                    options={optionFormaPagamento}
                                                    getOptionLabel={(option) => option.label || ""}
                                                    renderInput={(params) => <FormField {...params} label="Forma de pagamento" />}
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    value={formaPagamento}
                                                    onChange={(event, newValue) => {
                                                        setFormaPagamento(newValue);
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={2}>{/*BANCO_CAIXA*/}
                                                <AutoCompleteStyled
                                                    options={optionBanco}
                                                    getOptionLabel={(option) => option.label || ""}
                                                    renderInput={(params) => <FormField {...params} label="Conta de Pagamento:" />}
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    value={banco}
                                                    onChange={(event, newValue) => {
                                                        setBanco(newValue);
                                                    }}
                                                />
                                            </Grid>
                                            {(parcelamento?.value < 1 || parcelamento?.value === '') &&
                                                <>
                                                    <Grid item xs={12} sm={1}>{/*PAGO ou RECEBIDO*/}
                                                        <MDBox width="100%" display="flex" alignItems='center' flexDirection='row'>
                                                            <Checkbox
                                                                checked={pago}
                                                                onChange={(e) => setPago(e.target.checked)}
                                                            />
                                                            <MDTypography variant="caption">
                                                                {tipo == 'D' ? 'Pago ' : 'Recebido '}
                                                            </MDTypography>
                                                            <Tooltip
                                                                title={`Marcar como ${tipo == 'D' ? 'pago' : 'recebido'}.`}
                                                                placement="top"
                                                            >
                                                                <HelpIcon color="info" />
                                                            </Tooltip>
                                                        </MDBox>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                tipo == 'D' &&
                                                <>
                                                    <Grid item xs={12} sm={1.5}>{/*AGENDADO*/}
                                                        <MDBox width="100%" display="flex" alignItems='center' flexDirection='row'>
                                                            <Checkbox
                                                                checked={agendado}
                                                                onChange={(e) => setAgendado(e.target.checked)}
                                                            />
                                                            <MDTypography variant="caption">
                                                                {'Agendado '}
                                                            </MDTypography>
                                                            <Tooltip
                                                                title={`Ao marcar como agendado, será considerada a data ${dateIsValid(vencimento) ? formatDate(vencimento) ? formatDate(vencimento, 'DD/MM/YYYY') : 'Data invalida' : 'Data invalida'}
                                            A baixa será feita no sistema somente quando for marcado manualmente como pago`}
                                                                placement="top"
                                                            >
                                                                <HelpIcon color="info" />
                                                            </Tooltip>
                                                        </MDBox>
                                                    </Grid>
                                                </>
                                            }

                                            {
                                                parcelamento.value >= 1 &&
                                                <Grid item xs={12} sm={2} > {/*COMPETENCIA CHECK*/}
                                                    <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                                                        <MDTypography variant='caption'>
                                                            {'Competência = Vencimento '}
                                                            <Tooltip
                                                                title={`Quando desabilitado abre a possibilidade de alterar a data de competência por parcela`}
                                                                placement="top"
                                                            >
                                                                <HelpIcon color="info" />
                                                            </Tooltip>
                                                        </MDTypography>
                                                        <Switch
                                                            checked={competenciaCheck}
                                                            onChange={(event) => setCompetenciaCheck(event.target.checked)}
                                                        />
                                                    </MDBox>
                                                </Grid>
                                            }

                                            {

                                                !competenciaCheck && parcelamento.value >= 1 &&
                                                <Grid item xs={12} sm={2} > {/*BOTÃO COPIA COMPETENCIA VENCIMENTO*/}
                                                    <MDButton onClick={handleClickCopiaCompetenciaVencimento} size='small' variant="gradient" type="button" color="info">
                                                        Copia competência da conta para as parcelas
                                                    </MDButton>
                                                </Grid>
                                            }

                                            {
                                                parcelamento.value >= 1 &&
                                                <>
                                                    <Grid item xs={12} sm={12}>{/*PARCELAS 1x ate 60x*/}
                                                        <DadosParcela
                                                            optionFormaPagamento={optionFormaPagamento}
                                                            optionBanco={optionBanco}
                                                            dadosParcelas={dadosParcelas}
                                                            setDadosParcelas={setDadosParcelas}
                                                            parcelamento={parcelamento}
                                                            nome={nome}
                                                            valor={valor}
                                                            banco={banco}
                                                            formaPagamento={formaPagamento}
                                                            vencimento={vencimento}
                                                            setVencimento={setVencimento}
                                                            intervaloParcelas={intervaloParcelas}
                                                            agendado={agendado}
                                                            tipo={tipo}
                                                            FechaTelaEspera={FechaTelaEspera}
                                                            MostraTelaEspera={MostraTelaEspera}
                                                            valorOnBlur={valorOnBlur}
                                                            competenciaCheck={competenciaCheck}
                                                        />
                                                    </Grid>
                                                </>
                                            }


                                            <Grid item xs={12} sm={12}> {/* OBSERVACAO da conta*/}
                                                <FormField
                                                    type="text"
                                                    label="Observação da conta"
                                                    value={observacao}
                                                    onChange={(e) => setObservacao(e.target.value)}
                                                    rows={3}
                                                    multiline={true}
                                                />
                                            </Grid>

                                        </Grid>
                                    </MDBox >

                                    :
                                    <MDBox ml={2} mr={2} mt={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/*BOTÃO ADD PARCELA*/}
                                        <Grid container spacing={2} mt={2} p={2}>
                                            <Grid item xs={12} sm={12}> {/* TITULO*/}
                                                <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='row'>
                                                    <MDTypography fontWeight="bold" pb={2}>Adicionar novas parcelas  </MDTypography>
                                                    <Tooltip
                                                        title="Para adicionar novas parcelas precisa-se ter uma diferença positiva de valor da
                                                    para que esse valor seja direcionado para as novas parcelas."
                                                        placement="top"
                                                    >
                                                        <HelpIcon color="info" />
                                                    </Tooltip>
                                                </MDBox>
                                                <Divider
                                                    sx={{ bgcolor: (theme) => theme.palette.divider }}
                                                    style={{
                                                        marginTop: 20,
                                                        border: "none",
                                                        height: 2,
                                                        margin: 0,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={1.2}>{/*BOTÃO ADCIONAR*/}
                                                {
                                                    dadosParcelasLancamento.map((item) => {
                                                        valor_total_parcela_lancamento2 = valor_total_parcela_lancamento2 + parseFloat(item.valor)
                                                    })
                                                }
                                                {
                                                    dadosParcelas.map((item) => {
                                                        valor_total_parcela2 = valor_total_parcela2 + parseFloat(item.valor)
                                                    })
                                                }
                                                <MDButton
                                                    disabled={(valor - valor_total_parcela2 - valor_total_parcela_lancamento2) > 0 ? false : true}
                                                    onClick={() => {
                                                        let vetorTemp = [];
                                                        vetorTemp.push({
                                                            vencimento: new Date(),
                                                            data_competencia: new Date(),
                                                            valor: valor - valor_total_parcela2 - valor_total_parcela_lancamento2,
                                                            formaPagamento: { value: "", label: "" },
                                                            banco: { value: "", label: "" },
                                                            descricao: '',
                                                            agendado: false,
                                                            observacao: ''
                                                        },)
                                                        setParcelamento(optionParcelamento[0])
                                                        setDadosParcelas(vetorTemp)
                                                    }}
                                                    variant="gradient"
                                                    type="button"
                                                    color="info"
                                                >
                                                    adicionar
                                                </MDButton>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                            }

                            <MDBox ml={2} mr={2} mt={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/*SUMÁRIO*/}
                                <Grid container spacing={2} mt={2} p={2}>

                                    <Grid item xs={12} sm={12}> {/* Titulo */}
                                        <Typography variant='title' fontWeight="bold" >TOTAIS</Typography>
                                        <Divider
                                            sx={{ bgcolor: (theme) => theme.palette.divider }}
                                            style={{
                                                marginTop: 20,
                                                border: "none",
                                                height: 2,
                                                margin: 0,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}> {/*ESPAÇO EM BRANCO*/}
                                    </Grid>

                                    <Grid item xs={12} sm={2}> {/*VALOR CONTA*/}
                                        <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                                            <MDTypography variant='subtitle2'>
                                                {'Valor da conta'}
                                            </MDTypography>

                                            <MDTypography fontWeight="bold" variant='body1'>
                                                {formataValor(valor)}
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>

                                    <Grid item xs={12} sm={2}> {/*VALOR PARCELAS LANCAMENTO*/}
                                        <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                                            <MDTypography variant='subtitle2'>
                                                {'Parcelas com lançamentos'}
                                            </MDTypography>

                                            <MDTypography fontWeight="bold" variant='body1'>
                                                {/* {formataValor(valor_total_parcela_lancamento)} */}
                                                {
                                                    dadosParcelasLancamento.map((item) => {
                                                        valor_total_parcela_lancamento = parseFloat(valor_total_parcela_lancamento.toFixed(2)) + parseFloat(parseFloat(item.valor).toFixed(2))
                                                    })

                                                }
                                                {formataValor(valor_total_parcela_lancamento)}
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>

                                    <Grid item xs={12} sm={2}> {/*VALOR PARCELAS*/}
                                        <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                                            <MDTypography variant='subtitle2'>
                                                {'Parcelas sem lançamentos'}
                                            </MDTypography>

                                            <MDTypography fontWeight="bold" variant='body1'>
                                                {/* {formataValor(valor_total_parcela)} */}
                                                {
                                                    dadosParcelas.map((item) => {
                                                        valor_total_parcela = parseFloat(valor_total_parcela.toFixed(2)) + parseFloat(parseFloat(item.valor).toFixed(2))
                                                    })

                                                }
                                                {formataValor(valor_total_parcela)}

                                            </MDTypography>
                                        </MDBox>
                                    </Grid>

                                    <Grid item xs={12} sm={2} > {/*DIFERENÇA*/}
                                        <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                                            <MDTypography variant='subtitle2'>
                                                {'Diferença'}
                                            </MDTypography>

                                            <MDTypography fontWeight="bold" variant='body1'>
                                                {(formataValor(valor - valor_total_parcela - valor_total_parcela_lancamento))}
                                                {/* {setDiferenca(0)} */}
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </form>
                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*BOTÕES*/}
                    <MDBox width="100%">
                        <MDBox width="100%" display="flex" justifyContent="space-between">
                            <MDButton onClick={() => {
                                handleCancel()
                                limpaImputs()
                            }} variant="gradient" type="button" color="secondary">
                                voltar
                            </MDButton>
                            <MDButton
                                disabled={parseFloat((valor - valor_total_parcela - valor_total_parcela_lancamento).toFixed(2)) == 0 ? false : true}
                                onClick={handleClickGravar}
                                variant="gradient"
                                type="submit"
                                //type="button" 
                                color="info">
                                salvar
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog>
        </>
    );
}